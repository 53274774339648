var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Section Pricing",
    attrs: {
      "id": "pricing"
    }
  }, [_vm._m(0), _vm.showPromotion ? _c('div', {
    staticClass: "PromotionBanner"
  }, [_vm._m(1), _vm._m(2)]) : _vm._e(), _vm._m(3), _c('div', {
    staticClass: "Price-Wrap"
  }, [_c('Card', {
    staticClass: "Price-Card Price-Card--highlight"
  }, [_c('div', {
    staticClass: "PlanTag PlanTag--blue"
  }, [_vm._v("Günstigster Preis in Deutschland")]), _c('div', {
    staticClass: "PlanLabel"
  }, [_vm._v("Lite")]), _c('div', {
    staticClass: "PlanDescription"
  }, [_vm._v(" Gesetzliche Anforderungen erfüllen ")]), _c('h3', {
    staticClass: "PlanPrice"
  }, [_vm.showPromotion ? _c('div', [_c('span', {
    staticClass: "OldPrice"
  }, [_vm._v("10€")]), _vm._v(" 5€ ")]) : _c('div', [_vm._v(" 10€ ")]), _c('span', {
    staticClass: "Info"
  }, [_vm._v(" pro Monat / pro Taxi")])]), _vm.showPromotion ? _c('div', {
    staticClass: "PromotionNote"
  }, [_c('i', {
    staticClass: "ri-time-line"
  }), _vm._v(" Angebot gültig bis 30. April 2025 ")]) : _vm._e(), _c('ul', {
    staticClass: "FeatureList"
  }, [_c('li', [_c('span', [_vm._v("Datenspeicherung in Echtzeit")])]), _c('li', [_c('span', [_vm._v("SIM-Karte ohne Vertragsbindung")])]), _c('li', [_c('span', [_vm._v("TSE Gateway Gebühren inklusive")])]), _c('li', [_c('span', [_vm._v("Mitarbeiterverwaltung")])]), _c('li', [_c('span', [_vm._v("Schichtmanagement")])])]), _vm.showPromotion ? _c('p', {
    staticClass: "PromotionFeature"
  }, [_c('i', {
    staticClass: "ri-checkbox-circle-line"
  }), _c('span', [_vm._v("GRATIS myPOS Go 2 Terminal")])]) : _vm._e(), _c('div', {
    staticClass: "PlanCta"
  }, [_vm.showPromotion ? _c('router-link', {
    staticClass: "Button",
    attrs: {
      "to": "/register"
    }
  }, [_vm._v("Aktionsangebot sichern")]) : _c('router-link', {
    staticClass: "Button",
    attrs: {
      "to": "/register"
    }
  }, [_vm._v("Auswählen")])], 1)]), false ? _c('Card', {
    staticClass: "Price-Card"
  }, [_c('div', {
    staticClass: "PlanLabel"
  }, [_vm._v("Basic")]), _c('div', {
    staticClass: "PlanDescription"
  }, [_vm._v(" Alleinfahrende Einzelunternehmer ")]), _c('h3', {
    staticClass: "PlanPrice"
  }, [_vm.showPromotion ? _c('div', [_c('span', {
    staticClass: "OldPrice"
  }, [_vm._v("13€")]), _vm._v(" 6,50€ ")]) : _c('div', [_vm._v(" 13€ ")]), _c('span', {
    staticClass: "Info"
  }, [_vm._v(" pro Monat / pro Taxi")])]), _vm.showPromotion ? _c('div', {
    staticClass: "PromotionNote"
  }, [_c('i', {
    staticClass: "ri-time-line"
  }), _vm._v(" Angebot gültig bis 30. April 2025 ")]) : _vm._e(), _c('ul', {
    staticClass: "FeatureList"
  }, [_c('li', [_c('span', [_vm._v("Alles in Lite")])]), _c('li', [_c('span', [_vm._v("Mitarbeiterverwaltung")])]), _c('li', [_c('span', [_vm._v("Einzelfahrtenaufzeichnung")])]), _c('li', [_c('span', [_vm._v("Schichtmanagement")])]), _c('li', [_c('span', [_vm._v("Betriebsnachweise")])]), _c('li', [_c('span', [_vm._v("Arbeitszeitnachweise")])])]), _vm.showPromotion ? _c('p', {
    staticClass: "PromotionFeature"
  }, [_c('i', {
    staticClass: "ri-checkbox-circle-line"
  }), _c('span', [_vm._v("GRATIS myPOS Go 2 Terminal")])]) : _vm._e(), _c('div', {
    staticClass: "PlanCta"
  }, [_vm.showPromotion ? _c('router-link', {
    staticClass: "Button",
    attrs: {
      "to": "/register"
    }
  }, [_vm._v("Aktionsangebot sichern")]) : _c('router-link', {
    staticClass: "Button",
    attrs: {
      "to": "/register"
    }
  }, [_vm._v("Auswählen")])], 1)]) : _vm._e(), _c('Card', {
    staticClass: "Price-Card"
  }, [_c('div', {
    staticClass: "PlanLabel"
  }, [_vm._v("Basic")]), _c('div', {
    staticClass: "PlanDescription"
  }, [_vm._v(" Optimal für Unternehmen mit mehreren Fahrzeugen ")]), _c('h3', {
    staticClass: "PlanPrice"
  }, [_vm.showPromotion ? _c('div', [_c('span', {
    staticClass: "OldPrice"
  }, [_vm._v("16€")]), _vm._v(" 8€ ")]) : _c('div', [_vm._v(" 16€ ")]), _c('span', {
    staticClass: "Info"
  }, [_vm._v(" pro Monat / pro Taxi")])]), _vm.showPromotion ? _c('div', {
    staticClass: "PromotionNote"
  }, [_c('i', {
    staticClass: "ri-time-line"
  }), _vm._v(" Angebot gültig bis 30. April 2025 ")]) : _vm._e(), _c('ul', {
    staticClass: "FeatureList"
  }, [_c('li', [_c('span', [_vm._v("Alles in Lite")])]), _c('li', [_c('span', [_vm._v("Betriebsnachweise")])]), _c('li', [_c('span', [_vm._v("Arbeitszeitnachweise")])]), _c('li', [_c('b', [_c('span', [_vm._v("Integrierte taxiFusion Driver App")])]), _c('ul', {
    staticClass: "SubFeatureList"
  }, [_c('li', [_c('span', [_vm._v("Pausenzeiten ")])]), _c('li', [_c('span', [_vm._v("Fahrtmarkierungen und Anpassungen ")])]), _c('li', [_c('span', [_vm._v("Statistiken und Auswertungen")])])])])]), _vm.showPromotion ? _c('p', {
    staticClass: "PromotionFeature"
  }, [_c('i', {
    staticClass: "ri-checkbox-circle-line"
  }), _c('span', [_vm._v("GRATIS myPOS Go 2 Terminal")])]) : _vm._e(), _c('div', {
    staticClass: "DiscountInfo"
  }, [_c('span', {
    staticClass: "DiscountLabel"
  }, [_vm._v("Rabatte")]), _c('ul', [_c('li', [_vm._v("Für Einzelfahrer "), _c('span', {
    staticClass: "OldPrice"
  }, [_vm._v("13€")]), _vm._v(" 6,50€")]), _c('li', [_vm._v("Ab 10 Autos "), _c('span', {
    staticClass: "OldPrice"
  }, [_vm._v("15,20€")]), _vm._v(" 7,60€")]), _c('li', [_vm._v("Ab 20 Autos "), _c('span', {
    staticClass: "OldPrice"
  }, [_vm._v("14,40€")]), _vm._v(" 7,20€")])])]), _c('div', {
    staticClass: "PlanCta"
  }, [_vm.showPromotion ? _c('router-link', {
    staticClass: "Button",
    attrs: {
      "to": "/register"
    }
  }, [_vm._v("Aktionsangebot sichern")]) : _c('router-link', {
    staticClass: "Button",
    attrs: {
      "to": "/register"
    }
  }, [_vm._v("Auswählen")])], 1)])], 1), _c('div', {
    staticClass: "Modules"
  }, [_c('h4', {
    staticClass: "ModulesTitle"
  }, [_vm._v(" Zusatzfunktionen für Ihr Geschäft. ")]), _c('div', {
    staticClass: "ModulesWrap"
  }, [_c('Card', {
    staticClass: "ModuleCard"
  }, [_c('div', {
    staticClass: "ModuleIcon"
  }, [_c('i', {
    staticClass: "ri-alarm-warning-line"
  })]), _c('h5', [_vm._v("Erinnerungsfunktionen")]), _c('p', [_vm._v(" Erinnerungen für Reifen und Ölwechsel, Inspektionen, TÜV, P-Schein-Verlängerung und mehr. Verpassen Sie nie wieder wichtige Termine. ")])]), _c('Card', {
    staticClass: "ModuleCard"
  }, [_c('div', {
    staticClass: "ModuleIcon"
  }, [_c('i', {
    staticClass: "ri-bar-chart-2-line"
  })]), _c('h5', [_vm._v("Performance-Analyse")]), _c('p', [_vm._v(" Detaillierte Einblicke in die Leistung Ihrer Fahrer und Fahrzeuge für eine optimierte betriebliche Effizienz. ")])])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SectionHeader"
  }, [_c('p', {
    staticClass: "Subtitle"
  }, [_vm._v(" Spezielle Tarife für Ihre Taxiflotte. ")]), _c('h2', {
    staticClass: "Headline"
  }, [_vm._v(" Flexible Lösungen ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "PromotionIcon"
  }, [_c('i', {
    staticClass: "ri-alarm-warning-fill"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "PromotionContent"
  }, [_c('h3', [_vm._v("Sonderaktion: Nur bis 30. April 2025!")]), _c('p', [_vm._v(" 50% Rabatt auf alle Tarife + myPOS Go 2 Terminal kostenlos ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HeaderInfo"
  }, [_c('p', {
    staticClass: "SetupFee"
  }, [_c('i', {
    staticClass: "ri-settings-line Icon"
  }), _vm._v(" Es fallen 49€ einmalige Einrichtungsgebühr an. ")]), _c('p', {
    staticClass: "TaxInfo"
  }, [_c('i', {
    staticClass: "ri-information-line Icon"
  }), _vm._v(" Alle Preise verstehen sich zzgl. MwSt. ")])]);
}]

export { render, staticRenderFns }