<template>
    <div class="Section HeroSection">
        <div class="HeroOverlay"></div>
        <div class="Content">
            <div class="HeroContent">
                <div class="TextContent">
                    <h1 class="HeroTitle">
                        <span class="TitleHighlight">Ihr zuverlässiger Partner</span>
                        für moderne Taxiverwaltung
                    </h1>
                    <p class="HeroSubtitle">
                        Die intelligente Softwarelösung für die Taxibranche von morgen. Optimieren
                        Sie Ihre Flotte und steigern Sie Ihre Effizienz.
                    </p>
                    <div class="HeroActions">
                        <router-link to="/register" class="PrimaryAction">
                            <SiteButton>
                                <span class="ButtonText">Jetzt starten</span>
                                <i class="ri-arrow-right-line ButtonIcon"></i>
                            </SiteButton>
                        </router-link>
                        <a href="#features" class="SecondaryAction">
                            <span>Mehr erfahren</span>
                            <i class="ri-arrow-down-double-line"></i>
                        </a>
                    </div>

                    <div class="FeatureHighlights">
                        <div class="FeatureItem">
                            <div class="FeatureIcon">
                                <i class="ri-emotion-happy-line"></i>
                            </div>
                            <span>Benutzerfreundlich</span>
                        </div>
                        <div class="FeatureItem">
                            <div class="FeatureIcon">
                                <i class="ri-money-dollar-circle-line"></i>
                            </div>
                            <span>Kosten reduzieren</span>
                        </div>
                        <div class="FeatureItem">
                            <div class="FeatureIcon">
                                <i class="ri-line-chart-line"></i>
                            </div>
                            <span>Performance steigern</span>
                        </div>
                    </div>

                    <div class="HeroStats">
                        <div class="StatItem">
                            <span class="StatNumber">500+</span>
                            <span class="StatLabel">Zufriedene Kunden</span>
                        </div>
                        <div class="StatDivider"></div>
                        <div class="StatItem">
                            <span class="StatNumber">98%</span>
                            <span class="StatLabel">Kundenzufriedenheit</span>
                        </div>
                        <div class="StatDivider"></div>
                        <div class="StatItem">
                            <span class="StatNumber">24/7</span>
                            <span class="StatLabel">Support</span>
                        </div>
                    </div>
                </div>

                <div class="HeroVisual">
                    <div class="HeroImageContainer">
                        <img
                            src="/product/dashboard.png"
                            alt="TaxiFusion Dashboard"
                            class="HeroImage"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import anime from 'animejs';
import SiteButton from '@/components/SiteButton';

export default {
    components: {
        SiteButton,
    },
    methods: {
        animateHero() {
            // Staggered animation for text elements
            anime
                .timeline({
                    easing: 'easeOutExpo',
                })
                .add({
                    targets: '.HeroTitle',
                    opacity: [0, 1],
                    translateY: [40, 0],
                    duration: 1000,
                })
                .add(
                    {
                        targets: '.HeroSubtitle',
                        opacity: [0, 1],
                        translateY: [30, 0],
                        duration: 800,
                    },
                    '-=700',
                )
                .add(
                    {
                        targets: '.HeroActions',
                        opacity: [0, 1],
                        translateY: [20, 0],
                        duration: 600,
                    },
                    '-=600',
                )
                .add(
                    {
                        targets: '.FeatureHighlights',
                        opacity: [0, 1],
                        translateY: [20, 0],
                        duration: 600,
                    },
                    '-=500',
                )
                .add(
                    {
                        targets: '.HeroStats',
                        opacity: [0, 1],
                        translateY: [15, 0],
                        duration: 600,
                    },
                    '-=400',
                );

            // Hero image animation
            anime({
                targets: '.HeroImage',
                opacity: [0, 1],
                translateY: [50, 0],
                scale: [0.9, 1],
                duration: 1200,
                easing: 'easeOutCubic',
                delay: 300,
            });
        },
    },
    mounted() {
        this.animateHero();
    },
};
</script>

<style lang="scss">
.HeroSection {
    min-height: 90vh;
    color: var(--color-text-white);
    display: flex;
    flex-direction: column;
    position: relative;
    background-image: url('/banner.jpg');
    background-size: cover;
    background-position: center;
    overflow: hidden;

    @media (max-width: breakpoint(tabletPortrait)) {
        min-height: calc(100vh - 80px);
    }

    .HeroOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, rgba(0, 23, 50, 0.9) 0%, rgba(0, 0, 0, 0.65) 100%);
        z-index: 0;
    }

    .Content {
        @extend %sectionWrapper;
        position: relative;
        z-index: 1;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        margin: 80px auto 0;
    }

    .HeroContent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: breakpoint(tabletLandscape)) {
            flex-direction: column;
            text-align: center;
            padding-top: 80px;
            padding-bottom: 50px;
        }
    }

    .TextContent {
        position: relative;

        @media (max-width: breakpoint(tabletLandscape)) {
            width: 90%;
            margin-bottom: 60px;
        }
    }

    .HeroTitle {
        font-size: 3.8rem;
        font-weight: 800;
        margin: 0 0 25px;
        line-height: 1.2;
        letter-spacing: -0.02em;
        color: white;
        opacity: 0;

        .TitleHighlight {
            display: block;
            position: relative;
            color: #ffe89e;
        }

        @media (max-width: breakpoint(laptop)) {
            font-size: 3.2rem;
        }

        @media (max-width: breakpoint(tabletPortrait)) {
            font-size: 2.5rem;
        }

        @media (max-width: breakpoint(mobile)) {
            font-size: 2rem;
        }
    }

    .HeroSubtitle {
        font-size: 1.25rem;
        line-height: 1.6;
        margin: 0 0 40px;
        color: rgba(255, 255, 255, 0.9);
        max-width: 600px;
        opacity: 0;

        @media (max-width: breakpoint(tabletLandscape)) {
            margin: 0 auto 40px;
        }

        @media (max-width: breakpoint(mobile)) {
            font-size: 1.125rem;
        }
    }

    .HeroActions {
        display: flex;
        gap: 20px;
        margin-bottom: 40px;
        opacity: 0;

        @media (max-width: breakpoint(tabletLandscape)) {
            justify-content: center;
        }

        @media (max-width: breakpoint(mobile)) {
            flex-direction: column;
            gap: 15px;
        }

        .PrimaryAction {
            text-decoration: none;

            .SiteButton {
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 16px 32px;
                font-size: 1.125rem;
                border-radius: 8px;
                background-color: #ffe89e;
                color: #2d3748;
                box-shadow: 0 10px 20px rgba(255, 232, 158, 0.3);
                transition: all 0.3s ease;

                &:hover {
                    transform: translateY(-3px);
                    box-shadow: 0 15px 25px rgba(255, 232, 158, 0.4);
                    background-color: darken(#ffe89e, 5%);

                    .ButtonIcon {
                        transform: translateX(5px);
                    }
                }

                .ButtonIcon {
                    transition: transform 0.3s ease;
                }

                @media (max-width: breakpoint(mobile)) {
                    width: 100%;
                    justify-content: center;
                }
            }
        }

        .SecondaryAction {
            display: flex;
            align-items: center;
            gap: 8px;
            color: rgba(255, 255, 255, 0.9);
            text-decoration: none;
            font-weight: 500;
            font-size: 1.125rem;
            padding: 8px 16px;
            border-radius: 8px;
            transition: all 0.3s ease;

            svg {
                margin-top: 2px;
                transition: transform 0.3s ease;
            }

            &:hover {
                color: white;
                background-color: rgba(255, 255, 255, 0.3);

                svg {
                    transform: translateY(3px);
                }
            }

            @media (max-width: breakpoint(mobile)) {
                width: 100%;
                justify-content: center;
            }
        }
    }

    .FeatureHighlights {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        opacity: 0;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10px);
        padding: 10px 16px;
        border-radius: 100px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        max-width: 600px;
        gap: 24px;

        &:hover {
            background: rgba(255, 255, 255, 0.15);
            transform: translateY(-3px);
        }
        @media (max-width: breakpoint(tabletLandscape)) {
            justify-content: center;
        }

        @media (max-width: breakpoint(mobile)) {
            display: none;
            justify-content: center;
        }

        .FeatureItem {
            display: flex;
            align-items: center;
            gap: 8px;

            transition: all 0.3s ease;
            font-size: 0.9rem;

            .FeatureIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 28px;
                height: 28px;
                background: rgba(255, 232, 158, 0.2);
                border-radius: 50%;

                svg {
                    color: #ffe89e;
                    width: 16px;
                    height: 16px;
                }
            }

            span {
                font-size: 0.9rem;
                font-weight: 500;
                white-space: nowrap;
                color: white;
            }
        }
    }

    .HeroStats {
        display: flex;
        align-items: center;
        gap: 20px;
        opacity: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding-top: 30px;
        display: none;

        @media (max-width: breakpoint(tabletLandscape)) {
            justify-content: center;
        }

        @media (max-width: breakpoint(mobile)) {
            flex-direction: column;
            gap: 20px;
        }

        .StatItem {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (max-width: breakpoint(tabletLandscape)) {
                align-items: center;
            }
        }

        .StatNumber {
            font-size: 2rem;
            font-weight: 700;
            color: #ffe89e;
            line-height: 1;

            @media (max-width: breakpoint(mobile)) {
                font-size: 1.75rem;
            }
        }

        .StatLabel {
            font-size: 0.95rem;
            color: rgba(255, 255, 255, 0.7);
            margin-top: 5px;
        }

        .StatDivider {
            width: 1px;
            height: 40px;
            background-color: rgba(255, 255, 255, 0.2);

            @media (max-width: breakpoint(mobile)) {
                display: none;
            }
        }
    }

    .HeroVisual {
        width: 48%;
        position: relative;
        display: none;

        @media (max-width: breakpoint(tabletLandscape)) {
            width: 90%;
            max-width: 600px;
        }

        .HeroImageContainer {
            position: relative;
            z-index: 2;
            border-radius: 16px;
            overflow: hidden;
            border: 3px solid rgba(255, 232, 158, 0.3);
            box-shadow: 0 30px 60px rgba(0, 0, 0, 0.3), 0 15px 30px rgba(0, 0, 0, 0.2);
            transform: perspective(1000px) rotateY(-5deg) rotateX(5deg);
            transition: all 0.5s ease;

            &:hover {
                transform: perspective(1000px) rotateY(0deg) rotateX(0deg);
                box-shadow: 0 40px 70px rgba(0, 0, 0, 0.4), 0 20px 40px rgba(0, 0, 0, 0.3);
            }

            @media (max-width: breakpoint(tabletLandscape)) {
                transform: none;
                box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);

                &:hover {
                    transform: none;
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to bottom, transparent 80%, rgba(0, 0, 0, 0.3) 100%);
                z-index: 3;
            }
        }

        .HeroImage {
            width: 100%;
            height: auto;
            display: block;
            opacity: 0;
        }
    }
}
</style>
