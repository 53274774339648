<template>
    <div class="ContactSection" id="contact">
        <div class="SectionBackground">
            <div class="BackgroundPattern"></div>
        </div>

        <!-- New Headline Section -->
        <div class="ContactHeadline">
            <h2 class="MainHeadline">Fragen zu taxiFusion?</h2>
            <p class="SubHeadline">
                Wir sind für Sie da und helfen Ihnen gerne bei allen Anliegen rund um taxiFusion
            </p>
        </div>
        <!-- End New Headline Section -->

        <div class="ContactContainer" v-if="hasSendMessage" ref="messageSend">
            <div class="SuccessMessage">
                <div class="SuccessIcon">
                    <i class="ri-check-line"></i>
                </div>
                <h2>Ihre Nachricht wurde erfolgreich gesendet</h2>
                <p>
                    Vielen Dank für Ihre Kontaktaufnahme! Wir werden uns schnellstmöglich bei Ihnen
                    melden.
                </p>
                <div class="SuccessActions">
                    <button @click="resetForm" class="ResetButton">
                        <i class="ri-mail-line"></i>
                        Neue Nachricht senden
                    </button>
                </div>
            </div>
        </div>

        <div class="ContactContainer" v-else>
            <div class="ContactContent">
                <div class="ContactInfo">
                    <div class="SectionHeader">
                        <h2>Wir freuen uns auf Ihre Nachricht</h2>
                        <p>
                            Haben Sie Fragen zu taxiFusion? Unser Team steht Ihnen gerne zur
                            Verfügung.
                        </p>
                    </div>

                    <div class="ContactDetails">
                        <div class="ContactItem">
                            <div class="ContactItemIcon">
                                <i class="ri-mail-line"></i>
                            </div>
                            <div class="ContactItemContent">
                                <h3>E-Mail</h3>
                                <a href="mailto:info@taxifusion.de">info@taxifusion.de</a>
                            </div>
                        </div>

                        <div class="ContactItem">
                            <div class="ContactItemIcon">
                                <i class="ri-phone-line"></i>
                            </div>
                            <div class="ContactItemContent">
                                <h3>Telefon</h3>
                                <a href="tel:++493076006505">+49 30 76 00 65 05</a>
                            </div>
                        </div>

                        <div class="ContactItem">
                            <div class="ContactItemIcon">
                                <i class="ri-time-line"></i>
                            </div>
                            <div class="ContactItemContent">
                                <h3>Erreichbarkeit</h3>
                                <p>Dienstag: 14:00 - 16:00 Uhr</p>
                                <p>Donnerstag: 16:00 - 19:00 Uhr</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ContactFormWrapper">
                    <form @submit.prevent="submitForm" class="ContactForm">
                        <div class="FormRow">
                            <div class="InputGroup">
                                <label for="name">Ihr Name</label>
                                <div class="InputWithIcon">
                                    <i class="ri-user-line"></i>
                                    <input
                                        type="text"
                                        id="name"
                                        v-model="form.name"
                                        placeholder="Vollständiger Name"
                                        required
                                    />
                                </div>
                            </div>

                            <div class="InputGroup">
                                <label for="email">E-Mail Adresse</label>
                                <div class="InputWithIcon">
                                    <i class="ri-mail-line"></i>
                                    <input
                                        type="email"
                                        id="email"
                                        v-model="form.email"
                                        placeholder="name@beispiel.de"
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="InputGroup">
                            <label for="message">Ihre Nachricht</label>
                            <div class="TextareaWrapper">
                                <textarea
                                    id="message"
                                    v-model="form.message"
                                    placeholder="Wie können wir Ihnen helfen?"
                                    required
                                    @input="updateCharacterCount"
                                ></textarea>
                                <div
                                    class="CharacterCounter"
                                    :class="{ 'is-valid': characterCount >= minCharacterCount }"
                                >
                                    <span>{{ characterCount }}/{{ minCharacterCount }}</span>
                                    <div class="ProgressBar">
                                        <div
                                            class="Progress"
                                            :style="{
                                                width: `${Math.min(
                                                    (characterCount / minCharacterCount) * 100,
                                                    100,
                                                )}%`,
                                            }"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Honeypot field for spam prevention -->
                        <input
                            type="text"
                            name="cat"
                            v-model="form.cat"
                            class="honeypot"
                            aria-hidden="true"
                        />

                        <Button
                            type="submit"
                            class="SubmitButton"
                            size="large"
                            :isLoading="isSending"
                            :disabled="characterCount < minCharacterCount"
                        >
                            Nachricht senden
                            <i class="ri-send-plane-line"></i>
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Button from '@/components/widgets/Button';

export default {
    name: 'Contact',
    components: {
        Button,
    },
    data() {
        return {
            isSending: false,
            hasSendMessage: false,
            characterCount: 0,
            minCharacterCount: 20,
            form: {
                name: '',
                email: '',
                message: '',
                cat: '',
            },
        };
    },
    methods: {
        updateCharacterCount() {
            this.characterCount = this.form.message.length;
        },
        resetForm() {
            this.hasSendMessage = false;
            this.form = {
                name: '',
                email: '',
                message: '',
                cat: '',
            };
            this.characterCount = 0;
        },
        async submitForm() {
            if (this.form.message.length < this.minCharacterCount) {
                this.$toasted.error(
                    `Bitte geben Sie mindestens ${this.minCharacterCount} Zeichen ein.`,
                );
                return;
            }

            // Honeypot check
            if (this.form.cat.length > 0) return;

            try {
                this.isSending = true;
                await axios.post(`${process.env.VUE_APP_API_BASE_URL}/contact`, this.form);
                this.isSending = false;
                this.$toasted.success(
                    'Vielen Dank für Ihre Nachricht! Wir werden uns so schnell wie möglich bei Ihnen melden.',
                );

                this.hasSendMessage = true;
                this.$nextTick(() => {
                    const messageSend = this.$refs.messageSend;
                    if (messageSend) {
                        messageSend.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'nearest',
                        });
                    }
                });
            } catch (error) {
                this.isSending = false;
                this.$toasted.error(
                    'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
                );
                console.error('Contact form submission error:', error);
            }
        },
    },
};
</script>

<style lang="scss">
.ContactSection {
    position: relative;
    padding: 120px 0;
    overflow: hidden;

    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 80px 0;
    }

    .SectionBackground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 0;

        .BackgroundPattern {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: radial-gradient(rgba(74, 85, 104, 0.1) 1px, transparent 1px);
            background-size: 20px 20px;
            opacity: 0.75;
        }
    }

    /* New Contact Headline Styles */
    .ContactHeadline {
        text-align: center;
        max-width: 800px;
        margin: 0 auto 60px;
        position: relative;
        z-index: 1;
        padding: 0 20px;

        .MainHeadline {
            font-size: 2.75rem;
            font-weight: 800;
            color: #2d3748;
            margin: 0 0 20px;
            line-height: 1.2;

            @media (max-width: breakpoint(tabletPortrait)) {
                font-size: 2.2rem;
            }

            @media (max-width: breakpoint(mobile)) {
                font-size: 1.8rem;
            }
        }

        .SubHeadline {
            font-size: 1.2rem;
            color: #4a5568;
            line-height: 1.6;
            margin: 0 auto;

            @media (max-width: breakpoint(mobile)) {
                font-size: 1.1rem;
            }
        }
    }

    .ContactContainer {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
        position: relative;
        z-index: 1;

        @media (max-width: breakpoint(tabletPortrait)) {
            padding: 0 25px;
        }
    }

    .ContactContent {
        display: flex;
        background: white;
        border-radius: 16px;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
        overflow: hidden;

        @media (max-width: breakpoint(tabletPortrait)) {
            flex-direction: column;
        }
    }

    .ContactInfo {
        flex: 0 0 35%;
        background: linear-gradient(
            135deg,
            var(--color-blue-dark) 0%,
            var(--color-blue-light) 100%
        );
        color: white;
        padding: 50px 40px;
        display: flex;
        flex-direction: column;

        @media (max-width: breakpoint(tabletPortrait)) {
            padding: 40px 30px;
        }

        .SectionHeader {
            margin-bottom: 40px;

            h2 {
                font-size: 2rem;
                font-weight: 700;
                margin: 0 0 20px;
                color: white;
                line-height: 1.3;

                @media (max-width: breakpoint(tabletPortrait)) {
                    font-size: 1.8rem;
                }
            }

            p {
                font-size: 1rem;
                line-height: 1.6;
                margin: 0;
                color: rgba(255, 255, 255, 0.9);
            }
        }

        .ContactDetails {
            margin-top: auto;

            .ContactItem {
                display: flex;
                align-items: center;
                margin-bottom: 25px;

                &:last-child {
                    margin-bottom: 0;
                }

                .ContactItemIcon {
                    flex: 0 0 40px;
                    height: 40px;
                    background-color: rgba(255, 255, 255, 0.2);
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 15px;

                    i {
                        font-size: 20px;
                        color: white;
                    }
                }

                .ContactItemContent {
                    h3 {
                        font-size: 1rem;
                        font-weight: 600;
                        margin: 0 0 5px;
                        color: white;
                    }

                    a,
                    p {
                        font-size: 1rem;
                        color: white;
                        margin: 0;
                        text-decoration: none;
                        transition: opacity 0.2s;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }

    .ContactFormWrapper {
        flex: 1;
        padding: 50px;

        @media (max-width: breakpoint(tabletPortrait)) {
            padding: 40px 25px;
        }
    }

    .ContactForm {
        .FormRow {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            margin-bottom: 20px;

            @media (max-width: breakpoint(mobile)) {
                grid-template-columns: 1fr;
            }
        }

        .InputGroup {
            margin-bottom: 25px;

            label {
                display: block;
                font-size: 0.95rem;
                font-weight: 600;
                color: #4a5568;
                margin-bottom: 8px;
            }

            .InputWithIcon {
                position: relative;

                i {
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #a0aec0;
                    font-size: 18px;
                }

                input {
                    width: 100%;
                    padding: 15px 15px 15px 45px;
                    border: 1px solid #e2e8f0;
                    border-radius: 10px;
                    font-size: 1rem;
                    outline: none;
                    transition: all 0.3s;

                    &:focus {
                        border-color: #3182ce;
                        box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.15);
                    }

                    &::placeholder {
                        color: #cbd5e0;
                    }
                }
            }

            .TextareaWrapper {
                position: relative;

                textarea {
                    width: 100%;
                    padding: 15px;
                    border: 1px solid #e2e8f0;
                    border-radius: 10px;
                    min-height: 150px;
                    font-size: 1rem;
                    outline: none;
                    resize: vertical;
                    transition: border-color 0.3s, box-shadow 0.3s;

                    &:focus {
                        border-color: #3182ce;
                        box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.15);
                    }

                    &::placeholder {
                        color: #cbd5e0;
                    }
                }

                .CharacterCounter {
                    position: absolute;
                    bottom: 15px;
                    right: 5px;
                    background: rgba(255, 255, 255, 0.9);
                    padding: 5px 10px;
                    border-radius: 100px;
                    border: 1px solid #e2e8f0;
                    display: flex;
                    align-items: center;
                    font-size: 0.8rem;
                    color: #718096;
                    transition: all 0.3s;

                    &.is-valid {
                        color: #48bb78;
                        border-color: #c6f6d5;
                        background-color: #f0fff4;
                    }

                    span {
                        margin-right: 8px;
                    }

                    .ProgressBar {
                        width: 50px;
                        height: 5px;
                        background-color: #e2e8f0;
                        border-radius: 100px;
                        overflow: hidden;

                        .Progress {
                            height: 100%;
                            background-color: #cbd5e0;
                            border-radius: 100px;
                            transition: width 0.3s, background-color 0.3s;
                        }

                        .is-valid & .Progress {
                            background-color: #48bb78;
                        }
                    }
                }
            }
        }

        .SubmitButton {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-top: 10px;

            i {
                font-size: 18px;
            }

            &:disabled {
                opacity: 0.7;
                cursor: not-allowed;
            }
        }

        .honeypot {
            position: absolute;
            left: -9999px;
            opacity: 0;
            height: 0;
            width: 0;
            z-index: -1;
        }
    }

    .SuccessMessage {
        background-color: white;
        border-radius: 16px;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
        text-align: center;
        padding: 60px 40px;
        max-width: 600px;
        margin: 0 auto;

        .SuccessIcon {
            width: 80px;
            height: 80px;
            background-color: #f0fff4;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 30px;
            border: 5px solid #c6f6d5;

            i {
                font-size: 40px;
                color: #48bb78;
            }
        }

        h2 {
            font-size: 1.8rem;
            font-weight: 700;
            color: #2d3748;
            margin: 0 0 15px;
        }

        p {
            font-size: 1.1rem;
            color: #718096;
            margin: 0 0 30px;
            line-height: 1.6;
        }

        .SuccessActions {
            .ResetButton {
                display: inline-flex;
                align-items: center;
                gap: 10px;
                background-color: #ebf8ff;
                color: #3182ce;
                border: none;
                border-radius: 10px;
                padding: 12px 24px;
                font-size: 1rem;
                font-weight: 600;
                cursor: pointer;
                transition: all 0.3s;

                i {
                    font-size: 18px;
                }

                &:hover {
                    background-color: #bee3f8;
                }
            }
        }
    }
}
</style>
