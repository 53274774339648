<template>
    <button
        class="Button"
        tabindex="0"
        :disabled="isDisabled"
        :class="[
            `is-${variant}`,
            { 'is-disabled': isDisabled },
            { 'has-button': hasIcon },
            { 'is-large': size === 'large' },
            { 'is-small': size === 'small' },
            { 'is-loading': isLoading },
        ]"
        :style="style"
        @click="handleClick"
    >
        <div class="Button-Wrapper">
            <Spinner v-if="isLoading" />
            <span v-if="hasIcon" class="IconBefore" ref="iconBefore">
                <slot name="iconBefore"></slot>
            </span>
            <span class="Label">
                <span v-if="value">{{ value }}</span>
                <slot></slot>
            </span>
        </div>
    </button>
</template>

<script>
import Spinner from '@/components/widgets/Spinner';

export default {
    name: 'Button',
    components: {
        Spinner,
    },
    props: {
        onClick: {
            type: Function,
            default: () => {},
        },
        isLoading: Boolean,
        size: {
            type: String,
        },
        keyName: {
            type: String,
            default: null,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: '',
        },
        marginBottom: {
            type: Number,
        },
        marginTop: {
            type: Number,
        },
        marginLeft: {
            type: Number,
        },
        marginRight: {
            type: Number,
        },
        variant: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            hasIcon: false,
        };
    },
    computed: {
        style() {
            const margins = ['Top', 'Bottom', 'Left', 'Right'];
            return margins.reduce((acc, direction) => {
                const propName = `margin${direction}`;
                if (this[propName] !== undefined) {
                    acc[propName] = `${this[propName] * 5}px`;
                }
                return acc;
            }, {});
        },
    },
    methods: {
        handleClick() {
            this.onClick();
            this.$emit('onClick');
        },
    },
    mounted() {
        const iconBefore = this.$refs?.iconBefore;

        if (iconBefore?.children?.length > 0) {
            this.hasIcon = true;
        }
    },
};
</script>

<style lang="scss">
.theme-dark {
    .Button {
        border: 1px solid var(--color-text-inactive);
        box-shadow: 0 0 0 0 rgba(rgb(219, 218, 218), 1);

        &.is-minimal {
            &:hover {
                color: var(--color-text-black);
                border: solid 1px var(--color-text-inactive);
                box-shadow: 0 0 0 4px rgba(rgb(219, 218, 218), 1);
                background-color: lighten(rgb(0, 0, 0), 10%);
            }
        }

        &.is-plain {
            &:hover {
                color: var(--color-text-black);
                border: solid 1px var(--color-text-inactive);
                box-shadow: 0 0 0 4px rgba(rgb(219, 218, 218), 1);
                background-color: lighten(rgb(0, 0, 0), 2%);
            }
        }
    }
}

.Button {
    font-family: inherit;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    display: inline-block;
    user-select: none;
    border-radius: 5px;
    box-shadow: 0 0 0 0 rgba(color(blue-dark), 1);
    background-color: var(--color-blue-dark);
    transition: all 0.3s ease;
    padding: 10px 25px;
    border: solid 2px transparent;

    @media (max-width: breakpoint(tabletLandscape)) {
        padding: 6px 18px;
    }

    &:hover {
        background-color: rgba(color(blue-light), 1);
        box-shadow: 0 0 0 4px rgba(color(blue-light), 1);
    }

    &:focus {
        box-shadow: 0 0 0 4px rgba(color(blue-light), 1);
        outline: solid 1px var(--color-text-inactive);
    }

    &.is-disabled {
        cursor: not-allowed;
        background-color: color(text-gray-dark);
        box-shadow: 0 0 0 0px rgba(color(grey), 1);
    }

    &.is-basic {
        background-color: #e3e2e2;
        color: black;
        box-shadow: 0 0 0 0 rgba(color(grey), 1);

        &:hover {
            box-shadow: 0 0 0 4px rgba(color(grey), 1);
            background-color: lighten(color(grey), 0%);
        }

        &:focus {
            box-shadow: 0 0 0 4px rgba(color(blue-light), 1);
            outline: solid 1px var(--color-text-inactive);
        }
    }

    &.is-icon {
        padding: 4px;
        color: var(--color-text-blue);
        background-color: transparent;
        border: solid 1px var(--color-text-inactive);
        font-weight: 400;
        box-shadow: 0 0 0 0 rgba(rgb(219, 218, 218), 1);

        i {
            font-size: 1.5rem;
        }

        &:hover {
            color: var(--color-text-black);
            border: solid 1px var(--color-text-inactive);
            box-shadow: 0 0 0 4px rgba(rgb(219, 218, 218), 1);
            background-color: darken(white, 10%);
        }

        &:focus {
            box-shadow: 0 0 0 4px rgba(color(blue-light), 1);
            outline: solid 1px var(--color-text-inactive);
        }
    }

    &.is-plain {
        color: var(--color-text-black);
        background-color: transparent;
        border: solid 1px var(--color-text-inactive);
        box-shadow: 0 0 0 0 rgba(rgb(219, 218, 218), 1);

        &:hover {
            color: var(--color-text-black);
            border: solid 1px var(--color-text-inactive);
            box-shadow: 0 0 0 4px rgba(rgb(219, 218, 218), 1);
            background-color: darken(white, 10%);
        }

        &:focus {
            box-shadow: 0 0 0 4px rgba(color(blue-light), 1);
            outline: solid 1px var(--color-text-inactive);
        }
    }

    &.is-minimal {
        color: var(--color-text-black);
        background-color: var(--color-white);
        border: solid 1px var(--color-text-inactive);
        box-shadow: 0 0 0 0px rgba(rgb(219, 218, 218), 1);

        &:hover {
            color: var(--color-text-black);
            border: solid 1px var(--color-text-inactive);
            box-shadow: 0 0 0 4px rgba(rgb(219, 218, 218), 1);
            background-color: lighten(white, 10%);
        }
    }

    &.is-primary {
        background-color: color(grey);
    }

    &.is-warning {
        background-color: color(yellow-end);
        box-shadow: 0 0 0 0px rgba(color(yellow-end), 1);
        color: black;

        &:hover {
            box-shadow: 0 0 0 4px rgba(color(yellow-end), 1);
            background-color: darken(color(yellow-end), 4%);
        }

        &:focus {
            box-shadow: 0 0 0 4px rgba(color(blue-light), 1);
            outline: solid 1px var(--color-text-inactive);
        }
    }

    &.is-success {
        background-color: color(green);
        color: black;
        box-shadow: 0 0 0 0px rgba(color(green), 1);

        &:hover {
            background-color: lighten(color(green), 10%);
            box-shadow: 0 0 0 4px rgba(color(green), 1);
        }

        &:focus {
            box-shadow: 0 0 0 4px rgba(color(blue-light), 1);
            outline: solid 1px var(--color-text-inactive);
        }
    }

    &.is-large {
        padding: 14px 28px 14px;

        .Label {
            font-size: 20px;
        }
    }

    &.is-small {
        padding: 6px 14px;

        &:hover {
            box-shadow: 0 0 0 2px rgba(color(blue-light), 1);
        }

        .Label {
            font-size: 12px;
        }
    }

    &.is-table {
        background: none;
        border: solid 1px rgba(0, 0, 0, 0.2);
        color: var(--color-blue);
        font-size: 0.85rem;
        padding: 8px 12px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease;
        box-shadow: none;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
            box-shadow: none;
        }

        &:active {
            transform: scale(0.97);
        }

        &:focus {
            box-shadow: 0 0 0 2px rgba(color(blue-light), 0.3);
            outline: none;
        }

        &.is-small {
            padding: 6px 10px;
            font-size: 0.75rem;
        }
    }

    .Button-Wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .IconBefore {
        margin-right: 5px;
        font-size: 0.95rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Label {
        font-size: 0.95rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.Button--LoadMore {
        margin-top: 20px;
        width: 100%;
        display: block;
    }

    &.is-loading {
        position: relative;
        user-select: none;
        pointer-events: none;

        .Spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .Label {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            opacity: 0;
        }
    }
}
</style>
