<template>
    <div class="SiteHeader" :class="{ 'is-scrolled': isScrolled, 'is-hidden': isHidden }">
        <div class="SiteHeader-Wrap">
            <router-link to="/" class="Logo">
                <img src="../assets/svg/logo.svg" alt="TaxiFusion Logo" />
            </router-link>

            <nav class="Navigation">
                <!-- Desktop Navigation -->
                <div class="DesktopNav">
                    <a
                        class="NavigationItem"
                        v-for="(item, index) in navItems"
                        :key="index"
                        :href="item.url"
                        :class="{ isActive: item.active }"
                    >
                        {{ item.label }}
                    </a>
                </div>

                <div class="AuthButtons">
                    <router-link to="/register" class="RegisterButton">
                        Registrieren
                    </router-link>
                    <router-link to="/sign-in">
                        <SiteButton size="small">
                            <i class="ri-login-box-line"></i>
                            Login
                        </SiteButton>
                    </router-link>
                </div>

                <button class="Hamburger" @click="toggleMobileMenu" aria-label="Menu">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </nav>
        </div>

        <transition name="slide-in">
            <div v-if="isMobileMenuOpen" class="MobileMenu">
                <div class="MobileMenuHeader">
                    <div class="BrandingMobile">
                        <img
                            src="../assets/svg/logo.svg"
                            alt="TaxiFusion Logo"
                            class="MobileLogo"
                        />
                        <span class="MobileLogoText">taxiFusion</span>
                    </div>
                    <button
                        class="MobileMenuClose"
                        @click="closeMobileMenu"
                        aria-label="Close menu"
                    >
                        <i class="ri-close-circle-line"></i>
                    </button>
                </div>

                <div class="MobileNavItems">
                    <a
                        v-for="(item, index) in navItems"
                        :key="index"
                        :href="item.url"
                        @click="closeMobileMenu"
                        :class="{ isActive: item.active }"
                    >
                        {{ item.label }}
                    </a>
                </div>

                <div class="MobileAuthButtons">
                    <router-link
                        to="/register"
                        class="MobileRegisterButton"
                        @click.native="closeMobileMenu"
                    >
                        Registrieren
                    </router-link>
                    <router-link
                        to="/sign-in"
                        class="MobileLoginButton"
                        @click.native="closeMobileMenu"
                    >
                        <i class="ri-login-box-line"></i>
                        Login
                    </router-link>
                </div>
            </div>
        </transition>

        <!-- Semi-transparent overlay when mobile menu is open -->
        <div v-if="isMobileMenuOpen" class="MobileMenuOverlay" @click="closeMobileMenu"></div>
    </div>
</template>
<script>
import SiteButton from './SiteButton';

export default {
    name: 'SiteHeader',
    components: {
        SiteButton,
    },
    data() {
        return {
            isScrolled: false,
            isHidden: false,
            isMobileMenuOpen: false,
            navItems: [
                { label: 'TaxiFusion', url: '#', active: true },
                { label: 'Features', url: '#features', active: false },
                { label: 'Preise', url: '#pricing', active: false },
                { label: 'Kontakt', url: '#contact', active: false },
            ],
            lastScrollY: 0,
            scrollThreshold: 50,
            scrollTimer: null,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        // Close mobile menu when resizing to desktop
        window.addEventListener('resize', this.handleResize);
        // Check initial scroll position
        this.handleScroll();
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
        if (this.scrollTimer) clearTimeout(this.scrollTimer);
    },
    methods: {
        handleScroll() {
            const currentScrollY = window.scrollY;
            // Always apply scrolled class if we're not at the top
            this.isScrolled = currentScrollY > 30;

            // Don't hide header if mobile menu is open
            if (this.isMobileMenuOpen) {
                return;
            }

            // Detect if we're at the very top or bottom of the page
            const isAtTop = currentScrollY < 100;
            const isAtBottom =
                window.innerHeight + currentScrollY >= document.body.scrollHeight - 50;
            console.log(document.body.offsetHeight - 50);
            // Always show header at the top or bottom of the page
            if (isAtTop || isAtBottom) {
                console.log('Header is always visible');
                this.isHidden = false;
            } else {
                // Check if we've scrolled enough to trigger a change
                const scrollDifference = currentScrollY - this.lastScrollY;

                if (Math.abs(scrollDifference) > this.scrollThreshold) {
                    // Scrolling down - hide header
                    if (scrollDifference > 0) {
                        this.isHidden = true;
                    }
                    // Scrolling up - show header
                    else {
                        this.isHidden = false;
                    }

                    this.lastScrollY = currentScrollY;
                }
            }

            // Reset the timer if it exists
            if (this.scrollTimer) clearTimeout(this.scrollTimer);

            // Set a timeout to show the header if user stops scrolling
            this.scrollTimer = setTimeout(() => {
                // If user has stopped scrolling for a while, peek the header slightly
                if (this.isHidden && !this.isMobileMenuOpen) {
                    document.addEventListener('mousemove', this.handleMouseNearTop, { once: true });
                }
            }, 1500);
        },
        handleMouseNearTop(event) {
            // If mouse is near the top edge of the viewport, show the header
            if (event.clientY < 60 && this.isHidden) {
                this.isHidden = false;
            }
        },
        toggleMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
            // Prevent body scrolling when menu is open
            document.body.style.overflow = this.isMobileMenuOpen ? 'hidden' : '';
            // Always show header when menu is open
            if (this.isMobileMenuOpen) {
                this.isHidden = false;
            }
        },
        closeMobileMenu() {
            this.isMobileMenuOpen = false;
            document.body.style.overflow = '';
        },
        handleResize() {
            if (window.innerWidth > 768 && this.isMobileMenuOpen) {
                this.closeMobileMenu();
            }
        },
    },
};
</script>

<style lang="scss">
.SiteHeader {
    width: 100%;
    position: fixed;
    z-index: 200;
    left: 0;
    right: 0;
    transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);
    background-color: transparent;
    top: 0;
    margin-top: 80px;
    transform: translateY(0);

    * {
        transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);
    }

    @media (max-width: 768px) {
        margin-top: 0px;
    }

    &.is-scrolled {
        padding: 0;
        transform: translateY(-25px);
        background-color: transparent;
        box-shadow: none;

        @media (max-width: 768px) {
            transform: translateY(0px);
        }

        // hide sign in sign button
        .SiteButton {
            display: none;

            @media (max-width: 768px) {
                display: flex; // Keep SiteButton visible on mobile when scrolled
            }
        }

        .SiteHeader-Wrap {
            margin: 10px auto;
            max-width: 650px;
            background: rgb(5 1 44 / 70%);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px); // For Safari
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 100px;
            padding: 10px 25px;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.1),
                inset 0 1px 1px rgba(255, 255, 255, 0.05);

            .NavigationItem,
            .RegisterButton {
                font-size: 14px;
            }

            @media (max-width: 768px) {
                background: transparent;
                border: none;
                border-radius: 0;
                backdrop-filter: blur(0);
                -webkit-backdrop-filter: blur(0); // For Safari
                max-width: unset;
                margin: 0;
                box-shadow: none;
                transform: translateY(0px);

                .Logo {
                    display: none;
                }
            }
        }

        .Logo {
            img {
                width: 40px;
                height: 40px;
            }

            .LogoText {
                font-size: 20px;
            }
        }

        .NavigationItem {
            padding: 8px 0;
        }
    }

    &.is-hidden {
        transform: translateY(-170px);
        box-shadow: none;

        @media (max-width: 768px) {
            transform: translateY(0px);
        }
    }

    .SiteHeader-Wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
        transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);
        @media (max-width: 768px) {
            padding: 15px;
        }
    }

    .Logo {
        display: flex;
        align-items: center;
        text-decoration: none;
        transition: all 0.3s ease;

        img {
            width: 50px;
            height: 50px;
            transition: all 0.3s ease;
        }

        .LogoText {
            color: white;
            font-size: 24px;
            font-weight: 700;
            margin-left: 12px;
            transition: all 0.3s ease;
            letter-spacing: 0.5px;
        }
    }

    .Navigation {
        display: flex;
        align-items: center;
    }

    .DesktopNav {
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
            display: none;
        }
    }

    .NavigationItem {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.85);
        letter-spacing: 0.5px;
        text-decoration: none;
        margin-right: 30px;
        font-weight: 500;
        transition: all 0.3s ease;
        padding: 5px 0;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px;
            background-color: white;
            transition: width 0.3s ease;
        }

        &:hover,
        &.isActive {
            color: white;

            &:after {
                width: 100%;
            }
        }
    }

    .AuthButtons {
        display: flex;
        align-items: center;

        .RegisterButton {
            color: white;
            font-size: 16px;
            text-decoration: none;
            margin-right: 20px;
            padding: 3px 15px;
            border-radius: 6px;
            transition: all 0.3s ease;
            border: 1px solid rgba(255, 255, 255, 0.3);

            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
                border-color: rgba(255, 255, 255, 0.5);
            }

            @media (max-width: 768px) {
                display: none; // Hide register button on mobile
            }
        }

        @media (max-width: 768px) {
            .SiteButton {
                position: absolute;
                top: -25px;
                right: 50px;
                width: 100px;
                z-index: 301;
                display: flex;
                padding: 9px 15px;
                border-radius: 50px;
                background: transparent;
                border: 1px solid rgba(255, 255, 255, 0.2);
                backdrop-filter: blur(15px);
                -webkit-backdrop-filter: blur(15px);
                span {
                    color: white;
                }
            }
        }
    }

    .Hamburger {
        display: none;
        background: none;
        border: none;
        padding: 10px;
        cursor: pointer;
        position: fixed;
        top: 15px;
        right: 15px;
        z-index: 301;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        transition: background-color 0.3s;
        margin-left: 10px;
        background: rgb(5 1 44 / 80%);
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);

        span {
            display: block;
            width: 25px;
            height: 2px;
            background-color: white;
            margin: 5px auto;
            transition: all 0.3s ease;
        }

        @media (max-width: 768px) {
            display: block;
        }
    }

    .MobileMenu {
        background: rgb(5 1 44 / 100%);
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 300px;
        display: flex;
        flex-direction: column;
        z-index: 301;
        box-shadow: -5px 0 30px rgba(0, 0, 0, 0.25);
        overflow-y: auto;
        border-left: 1px solid rgba(255, 255, 255, 0.08);

        .MobileMenuHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            .BrandingMobile {
                display: flex;
                align-items: center;

                .MobileLogo {
                    width: 35px;
                    height: 35px;
                }

                .MobileLogoText {
                    color: white;
                    font-size: 20px;
                    font-weight: 700;
                    margin-left: 10px;
                }
            }

            .MobileMenuClose {
                background: none;
                border: none;
                color: white;
                cursor: pointer;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: background-color 0.3s;

                i {
                    font-size: 28px;
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }
        }

        .MobileNavItems {
            padding: 20px 0;

            a {
                display: block;
                font-size: 18px;
                color: rgba(255, 255, 255, 0.85);
                padding: 15px 25px;
                text-decoration: none;
                transition: all 0.3s ease;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    left: 25px;
                    bottom: 10px;
                    width: 0;
                    height: 2px;
                    background-color: white;
                    transition: width 0.3s ease;
                }

                &:hover,
                &.isActive {
                    color: white;
                    background-color: rgba(255, 255, 255, 0.05);

                    &:after {
                        width: 30px;
                    }
                }
            }
        }

        .MobileAuthButtons {
            margin-top: auto;
            padding: 20px 20px 80px;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            display: flex;
            flex-direction: column;
            gap: 15px;

            .MobileRegisterButton,
            .MobileLoginButton {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 14px;
                text-decoration: none;
                border-radius: 8px;
                font-weight: 600;
                transition: all 0.3s ease;

                i {
                    margin-right: 8px;
                    font-size: 20px;
                }
            }

            .MobileRegisterButton {
                background-color: transparent;
                border: 1px solid rgba(255, 255, 255, 0.3);
                color: white;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                    border-color: rgba(255, 255, 255, 0.5);
                }
            }

            .MobileLoginButton {
                background-color: white;
                color: var(--color-blue-darker);

                &:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }

    .MobileMenuOverlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 299;
        backdrop-filter: blur(3px);
    }
}

// Animation for mobile menu
.slide-in-enter-active,
.slide-in-leave-active {
    transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.4s ease;
}

.slide-in-enter,
.slide-in-leave-to {
    transform: translateX(100%);
    opacity: 0;
}

// Optimize for Safari (which sometimes has issues with backdrop-filter)
@supports not (backdrop-filter: blur(10px)) {
    .SiteHeader {
        &.is-scrolled .SiteHeader-Wrap {
            background: rgba(23, 39, 85, 0.95);
        }
        .MobileMenu {
            background: rgba(23, 39, 85, 0.98);
        }
    }
}
</style>
