import axios from 'axios';
import { fakerDE } from '@faker-js/faker';
import { fakerTR } from '@faker-js/faker';

export const state = {
    drivers: [],
    employees: [],
    cars: [],
    car: {},
    modules: [],
    contactPerson: '',
    businessName: '',
    userVerified: true,
    user: {},
    planType: 'basic',
    planFeatures: {},
};

export const mutations = {
    setDrivers(state, drivers) {
        state.drivers = drivers;
    },
    setCars(state, cars) {
        state.cars = cars;
    },
    setModules(state, modules) {
        state.modules = modules;
    },
    setContactPerson(state, contactPerson) {
        // return `${fakerDE.person.firstName()} ${fakerDE.person.lastName()}`;
        state.contactPerson = contactPerson;
    },
    setBusinessName(state, businessName) {
        state.businessName = businessName;
    },
    setUserVerified(state, userVerified) {
        state.userVerified = userVerified;
    },
    setUser(state, user) {
        state.user = user;
    },
    setEmployees(state, employees) {
        state.employees = employees;
    },
    setCar(state, car) {
        state.car = car;
    },
    setPlanType(state, planType) {
        state.planType = planType;
    },
    setPlanFeatures(state, planFeatures) {
        state.planFeatures = planFeatures;
    },
};

export const actions = {
    async getProfile({ commit, state }) {
        const response = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}/cpanel/profile/simple`,
            {
                withCredentials: true,
            },
        );
        localStorage.setItem('authToken', 'token');

        const handleRandomName = () => {
            const { fakerDE, fakerTR, fakerAR } = require('@faker-js/faker');

            const names = {
                0: `${fakerDE.person.firstName()} ${fakerDE.person.lastName()}`,
                1: `${fakerTR.person.firstName()} ${fakerTR.person.lastName()}`,
            };
            return names[Math.floor(Math.random() * 2)];
        };
        const drivers = response.data.drivers.sort((a, b) => a?.value.localeCompare(b?.value));
        // .map(d => ({ ...d, name: handleRandomName() }));
        const cars = response.data.licenseNumbers.sort((a, b) => a?.value.localeCompare(b?.value));
        // .map(d => `B-${d.value.split('B-')[1].replace(/[a-z]/i, 0)}`);

        // Get plan type from response or use 'lite' as default
        // Here we assume the API returns planType as either 'lite' or 'basic'
        const planType = response.data.planType || 'basic';

        const moduleKeys = response.data.modules.map(module => module.staticId);
        commit('setDrivers', drivers);
        if (state.cars.length === 0) {
            commit('setCars', cars);
        }
        commit('setModules', moduleKeys);
        commit('setContactPerson', response.data.contactPerson);
        commit('setBusinessName', response.data.businessName);
        commit('setUserVerified', response.data.isVerified);
        commit('setUser', response.data);
        commit('setPlanType', planType);

        // Set available features based on plan type
        const planFeatures = determinePlanFeatures(planType);
        commit('setPlanFeatures', planFeatures);

        localStorage.setItem('drivers', JSON.stringify(drivers));
        localStorage.setItem('cars', JSON.stringify(cars));

        return response.data;
    },
    async getDrivers({ commit }) {
        const response = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}/cpanel/profile/simple`,
            {
                withCredentials: true,
            },
        );
        commit('setDrivers', response.data.drivers);
    },
    async getEmployees({ commit }) {
        try {
            const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/drivers`;
            const result = await axios.get(url, {
                withCredentials: true,
            });

            commit('setEmployees', result.data.filter(d => d?.isVisible));
        } catch (error) {
            this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
        }
    },
    async getCars({ commit }) {
        const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/cars`;
        const result = await axios.get(url, {
            withCredentials: true,
        });

        const response = result.data;
        const cars = response.map(car => ({
            ...car,
            id: car.installations?.[0]?.licenseNumber,
            value: car.installations?.[0]?.licenseNumber,
            uuid: car.uuid,
        }));
        commit('setCars', cars);
    },
    async getCar({ commit }, uuid) {
        const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/cars/${uuid}`;
        const result = await axios.get(url, {
            withCredentials: true,
        });

        const response = result.data;
        commit('setCar', response);
    },
    async getModules({ commit }) {
        const response = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}/cpanel/profile/simple`,
            {
                withCredentials: true,
            },
        );
        commit('setModules', response.data.modules);
    },
    async getContactPerson({ commit }) {
        const response = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}/cpanel/profile/simple`,
            {
                withCredentials: true,
            },
        );
        commit('setContactPerson', response.data.contactPerson);
    },
    async getBusinessName({ commit }) {
        const response = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}/cpanel/profile/simple`,
            {
                withCredentials: true,
            },
        );
        commit('setBusinessName', response.data.businessName);
    },

    setDrivers({ commit }, drivers) {
        commit('setDrivers', drivers);
    },
    setEmployees({ commit }, employees) {
        commit('setEmployees', employees);
    },
    setCars({ commit }, cars) {
        commit('setCars', cars);
    },
    setPlanType({ commit }, planType) {
        commit('setPlanType', planType);
        const planFeatures = determinePlanFeatures(planType);
        commit('setPlanFeatures', planFeatures);
    },
};

export const getters = {
    employees: state => state.employees,
    drivers: state => state.drivers,
    cars: state => state.cars,
    car: state => state.car,
    modules: state => state.modules,
    contactPerson: state => state.contactPerson,
    businessName: state => state.businessName,
    userVerified: state => state.userVerified,
    user: state => state.user,
    planType: state => state.planType,
    planFeatures: state => state.planFeatures,
    // Plan type getters
    isPlanLite: state => state.planType === 'lite',
    isPlanBasic: state => state.planType === 'basic',
    isPlanPro: state => state.planType === 'pro',
    isPlanUltimate: state => state.planType === 'ultimate',
    // Feature access getters
    canAddDriver: state => state.employees.length < state.planFeatures.maxDrivers,
    canAddVehicle: state => state.cars.length < state.planFeatures.maxVehicles,
    hasFeature: state => featureName => state.planFeatures[featureName] === true,
};

function determinePlanFeatures(planType) {
    const features = {
        lite: {
            maxDrivers: 3,
            maxVehicles: 5,
            advancedReporting: false,
            liveTracking: false,
            geoFencing: false,
            apiAccess: false,
        },
        basic: {
            maxDrivers: 10,
            maxVehicles: 20,
            advancedReporting: true,
            liveTracking: true,
            geoFencing: false,
            apiAccess: false,
        },
        pro: {
            maxDrivers: 25,
            maxVehicles: 50,
            advancedReporting: true,
            liveTracking: true,
            geoFencing: true,
            apiAccess: false,
        },
        ultimate: {
            maxDrivers: 50,
            maxVehicles: 100,
            advancedReporting: true,
            liveTracking: true,
            geoFencing: true,
            apiAccess: true,
        },
    };

    return features[planType] || features.lite;
}
