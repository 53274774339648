var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SiteHeader",
    class: {
      'is-scrolled': _vm.isScrolled,
      'is-hidden': _vm.isHidden
    }
  }, [_c('div', {
    staticClass: "SiteHeader-Wrap"
  }, [_c('router-link', {
    staticClass: "Logo",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/svg/logo.svg"),
      "alt": "TaxiFusion Logo"
    }
  })]), _c('nav', {
    staticClass: "Navigation"
  }, [_c('div', {
    staticClass: "DesktopNav"
  }, _vm._l(_vm.navItems, function (item, index) {
    return _c('a', {
      key: index,
      staticClass: "NavigationItem",
      class: {
        isActive: item.active
      },
      attrs: {
        "href": item.url
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _c('div', {
    staticClass: "AuthButtons"
  }, [_c('router-link', {
    staticClass: "RegisterButton",
    attrs: {
      "to": "/register"
    }
  }, [_vm._v(" Registrieren ")]), _c('router-link', {
    attrs: {
      "to": "/sign-in"
    }
  }, [_c('SiteButton', {
    attrs: {
      "size": "small"
    }
  }, [_c('i', {
    staticClass: "ri-login-box-line"
  }), _vm._v(" Login ")])], 1)], 1), _c('button', {
    staticClass: "Hamburger",
    attrs: {
      "aria-label": "Menu"
    },
    on: {
      "click": _vm.toggleMobileMenu
    }
  }, [_c('span'), _c('span'), _c('span')])])], 1), _c('transition', {
    attrs: {
      "name": "slide-in"
    }
  }, [_vm.isMobileMenuOpen ? _c('div', {
    staticClass: "MobileMenu"
  }, [_c('div', {
    staticClass: "MobileMenuHeader"
  }, [_c('div', {
    staticClass: "BrandingMobile"
  }, [_c('img', {
    staticClass: "MobileLogo",
    attrs: {
      "src": require("../assets/svg/logo.svg"),
      "alt": "TaxiFusion Logo"
    }
  }), _c('span', {
    staticClass: "MobileLogoText"
  }, [_vm._v("taxiFusion")])]), _c('button', {
    staticClass: "MobileMenuClose",
    attrs: {
      "aria-label": "Close menu"
    },
    on: {
      "click": _vm.closeMobileMenu
    }
  }, [_c('i', {
    staticClass: "ri-close-circle-line"
  })])]), _c('div', {
    staticClass: "MobileNavItems"
  }, _vm._l(_vm.navItems, function (item, index) {
    return _c('a', {
      key: index,
      class: {
        isActive: item.active
      },
      attrs: {
        "href": item.url
      },
      on: {
        "click": _vm.closeMobileMenu
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _c('div', {
    staticClass: "MobileAuthButtons"
  }, [_c('router-link', {
    staticClass: "MobileRegisterButton",
    attrs: {
      "to": "/register"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.closeMobileMenu.apply(null, arguments);
      }
    }
  }, [_vm._v(" Registrieren ")]), _c('router-link', {
    staticClass: "MobileLoginButton",
    attrs: {
      "to": "/sign-in"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.closeMobileMenu.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-login-box-line"
  }), _vm._v(" Login ")])], 1)]) : _vm._e()]), _vm.isMobileMenuOpen ? _c('div', {
    staticClass: "MobileMenuOverlay",
    on: {
      "click": _vm.closeMobileMenu
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }