var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "Footer"
  }, [_c('div', {
    staticClass: "FooterContainer"
  }, [_c('div', {
    staticClass: "FooterTop"
  }, [_c('div', {
    staticClass: "BrandSection"
  }, [_c('router-link', {
    staticClass: "Logo",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/svg/logo.svg"),
      "alt": "TaxiFusion Logo"
    }
  })]), _c('p', {
    staticClass: "BrandTagline"
  }, [_vm._v("Die moderne Lösung für Taxiunternehmer")]), _vm._m(0)], 1), _c('nav', {
    staticClass: "FooterNavigation"
  }, [_vm._m(1), _c('div', {
    staticClass: "NavCol"
  }, [_c('h3', [_vm._v("taxiFusion")]), _c('ul', [_c('li', [_c('router-link', {
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_vm._v("Datenschutzerklärung")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/terms-of-service"
    }
  }, [_vm._v("Geschäftsbedingungen")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/impressum"
    }
  }, [_vm._v("Impressum")])], 1), _vm._m(2)])]), _vm._m(3)])]), _c('div', {
    staticClass: "FooterBottom"
  }, [_c('div', {
    staticClass: "Copyright"
  }, [_c('p', [_vm._v("© " + _vm._s(_vm.currentYear) + " taxiFusion. Alle Rechte vorbehalten.")])]), _c('div', {
    staticClass: "LegalLinks"
  }, [_c('router-link', {
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_vm._v("Datenschutz")]), _c('span', {
    staticClass: "Divider"
  }, [_vm._v("|")]), _c('router-link', {
    attrs: {
      "to": "/terms-of-service"
    }
  }, [_vm._v("AGB")]), _c('span', {
    staticClass: "Divider"
  }, [_vm._v("|")]), _c('router-link', {
    attrs: {
      "to": "/impressum"
    }
  }, [_vm._v("Impressum")])], 1)])]), _c('div', {
    staticClass: "FooterBackground"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1440 320",
      "preserveAspectRatio": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill": "rgba(255,255,255,0.03)",
      "d": "M0,224L60,213.3C120,203,240,181,360,181.3C480,181,600,203,720,224C840,245,960,267,1080,266.7C1200,267,1320,245,1380,234.7L1440,224L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
    }
  })])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SocialIcons"
  }, [_c('a', {
    staticClass: "SocialIcon",
    attrs: {
      "href": "#",
      "aria-label": "Facebook"
    }
  }, [_c('i', {
    staticClass: "ri-facebook-fill"
  })]), _c('a', {
    staticClass: "SocialIcon",
    attrs: {
      "href": "#",
      "aria-label": "Twitter"
    }
  }, [_c('i', {
    staticClass: "ri-twitter-fill"
  })]), _c('a', {
    staticClass: "SocialIcon",
    attrs: {
      "href": "#",
      "aria-label": "LinkedIn"
    }
  }, [_c('i', {
    staticClass: "ri-linkedin-fill"
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "NavCol"
  }, [_c('h3', [_vm._v("Produkt")]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "/#features"
    }
  }, [_vm._v("Features")])]), _c('li', [_c('a', {
    attrs: {
      "href": "/#pricing"
    }
  }, [_vm._v("Preise")])]), _c('li', [_c('a', {
    attrs: {
      "href": "/sign-in"
    }
  }, [_vm._v("Login")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": "/#contact"
    }
  }, [_vm._v("Kontakt")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "NavCol ContactCol"
  }, [_c('h3', [_vm._v("Kontakt")]), _c('div', {
    staticClass: "ContactInfo"
  }, [_c('div', {
    staticClass: "ContactItem"
  }, [_c('i', {
    staticClass: "ri-mail-line"
  }), _c('a', {
    attrs: {
      "href": "mailto:info@taxifusion.de"
    }
  }, [_vm._v("info@taxifusion.de")])]), _c('div', {
    staticClass: "ContactItem"
  }, [_c('i', {
    staticClass: "ri-phone-line"
  }), _c('a', {
    attrs: {
      "href": "tel:+493076006505"
    }
  }, [_vm._v("+49 30 76 00 65 05")])]), _c('div', {
    staticClass: "ContactItem"
  }, [_c('i', {
    staticClass: "ri-map-pin-line"
  }), _c('a', {
    attrs: {
      "href": "https://maps.google.com/?q=Buckower+Chaussee+110-113,+12277+Berlin",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v(" Buckower Chaussee 110-113"), _c('br'), _vm._v(" 12277 Berlin ")])])])]);
}]

export { render, staticRenderFns }