<template>
    <div class="Section Partners">
        <div class="SectionHeader">
            <span class="PreTitle">Starke Partner an Ihrer Seite</span>
            <h2 class="MainTitle">Unsere Partner</h2>
            <p class="SectionDescription">
                Wir kooperieren mit erstklassigen Anbietern, um Ihnen die beste
                Taxi-Management-Lösung zu bieten
            </p>
        </div>

        <div class="PartnerCategories">
            <!-- Taxameter Partners -->
            <div class="PartnerCategory">
                <div class="CategoryHeader">
                    <h3>Taxameter Partner</h3>
                    <span class="CategoryDescription">Zertifizierte Taxameter-Hersteller</span>
                </div>

                <div class="TaxameterGrid">
                    <Card
                        class="TaxameterCard"
                        v-for="(partner, index) in taxameterPartners"
                        :key="index"
                    >
                        <div class="PartnerImageContainer">
                            <img :src="`/partners/${partner.logo}`" :alt="partner.name" />
                        </div>
                        <div class="TaxameterInfo">
                            <h4>{{ partner.name }}</h4>
                            <span class="TaxameterDescription">{{ partner.description }}</span>
                        </div>
                    </Card>
                </div>
            </div>

            <!-- Umrüster Partners -->
            <div class="PartnerCategory">
                <div class="CategoryHeader">
                    <h3>Umrüster Partner</h3>
                    <!-- <span class="CategoryDescription">Professionelle Installation und Service</span> -->
                </div>

                <div v-if="false" class="PartnerGrid">
                    <Card
                        class="PartnerCard"
                        v-for="(partner, index) in umruesterPartners"
                        :key="index"
                    >
                        <div class="PartnerImageContainer">
                            <img :src="`/partners/${partner.logo}`" :alt="partner.name" />
                        </div>
                        <div class="PartnerContent">
                            <h4>{{ partner.name }}</h4>
                            <p>{{ partner.location }}</p>
                            <a :href="partner.link" target="_blank" class="AppointmentButton">
                                <span>Termin vereinbaren</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path d="M5 12h14"></path>
                                    <path d="M12 5l7 7-7 7"></path>
                                </svg>
                            </a>
                        </div>
                    </Card>
                </div>
                <p class="PartnerDescription">
                    Sie möchten Ihre Daten bei TaxiFusion sichern?
                    <br />
                    Sagen Sie einfach Ihrer Einbau-Werkstatt Bescheid!
                    <br />
                    <br />
                    Ist Ihre Werkstatt noch nicht als Partner registriert? Kein Problem, die
                    Freischaltung dauert nur wenige Werktage. Rufen Sie uns an unter
                    <a href="tel:+493076006505">+49 30 76 00 65 05</a>
                </p>
            </div>

            <!-- POS-Systems -->
            <div class="PartnerCategory">
                <div class="CategoryHeader">
                    <h3>Zahlungslösungen für Ihr Taxi</h3>
                    <span class="CategoryDescription"></span>
                </div>

                <div class="SpecialOfferContainer">
                    <div class="SpecialOffer">
                        <!-- Update the offer badge to be more prominent -->
                        <div class="OfferBadge" v-if="showPromotion">
                            <i class="ri-time-line" style="font-size: 20px; color: white;"></i>
                            <span>LIMITIERTE AKTION</span> Nur bis 30. April 2025!
                        </div>

                        <div class="OfferContent">
                            <div class="OfferInfo">
                                <div class="PartnerLogo">
                                    <img
                                        :src="`/partners/${paymentPartners.logo}`"
                                        :alt="paymentPartners.name"
                                    />
                                </div>
                                <h4>{{ paymentPartners.name }}</h4>
                                <div class="OfferDescription">
                                    <div class="SpecialPromotion">
                                        <div class="PromoHeader">
                                            <h4 v-if="showPromotion">
                                                myPOS Go 2 Terminal KOSTENLOS
                                            </h4>
                                            <h4 v-else>myPOS Go 2 Terminal für 19€</h4>
                                        </div>

                                        <p class="PromoDescription" v-if="showPromotion">
                                            Bei Registrierung erhalten Sie myPOS Go 2 Terminal
                                            komplett kostenlos! Dieses Angebot ist nur bis zum 30.
                                            April 2025 gültig.
                                        </p>
                                        <p v-else>{{ paymentPartners.description }}</p>

                                        <div class="PromoBenefits" v-if="showPromotion">
                                            <div class="PromoBenefit">
                                                <i class="ri-check-line"></i>
                                                <span>Wert: 39€ GRATIS</span>
                                            </div>
                                            <div class="PromoBenefit">
                                                <i class="ri-check-line"></i>
                                                <span>Keine versteckten Kosten</span>
                                            </div>
                                            <!-- <div class="PromoBenefit">
                                                <i class="ri-check-line"></i>
                                                <span>Perfekte Integration</span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <a :href="paymentPartners.link" class="SpecialOfferButton">
                                    <span v-if="showPromotion">Gratis Terminal sichern</span>
                                    <span v-else>Jetzt kaufen</span>
                                    <i class="ri-arrow-right-line" style="font-size: 20px"></i>
                                </a>
                            </div>
                            <div class="ProductImage">
                                <!-- <div class="PromoPriceTag">GRATIS</div> -->
                                <img src="/partners/myPOS-GO2.webp" alt="alle taxiFusion Tarife" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Card from '@/components/Card.vue';

export default {
    name: 'Partners',
    components: {
        Card,
    },
    data() {
        return {
            taxameterPartners: [
                {
                    logo: 'hale.png',
                    name: 'Hale',
                },
                {
                    logo: 'semitron.png',
                    name: 'Semitron',
                },
                {
                    logo: 'digitax.png',
                    name: 'Digitax',
                },
            ],
            umruesterPartners: [
                {
                    logo: 'heedfeld.png',
                    location: 'Berlin',
                    link: 'https://www.heedfeld-berlin.de/kontakt/',
                },
                {
                    logo: 'hico.png',
                    location: 'Berlin',
                    link: 'https://www.hico-berlin.de',
                },
                {
                    logo: 'kraft.png',
                    location: 'Berlin',
                    link: 'https://www.taxameter-kraft.de/',
                },
                {
                    logo: 'koeln.png',
                    location: 'Köln',
                    link: 'https://www.taxikoeln.eu/contact.html',
                },
            ],
            paymentPartners: {
                logo: 'mypos.png',
                description:
                    'Exklusive Konditionen für TaxiFusion Kunden. Profitieren Sie von besseren Tarifen und schnellerer Abwicklung.',
                link: '/register',
            },
        };
    },
    computed: {
        showPromotion() {
            return new Date() < new Date('2025-04-30T23:59:59');
        },
    },
};
</script>

<style lang="scss">
.Partners {
    padding: 120px 75px;
    position: relative;
    overflow: hidden;
    @extend %sectionWrapper;

    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 60px 20px;
    }

    .SectionHeader {
        margin-bottom: 80px;
        position: relative;
    }

    .PreTitle {
        display: block;
        font-size: 1.125rem;
        font-weight: 500;
        letter-spacing: 0.05em;
        color: var(--color-blue-dark);
        margin-bottom: 12px;
    }

    .MainTitle {
        font-size: 3.5rem;
        font-weight: 700;
        margin: 0 0 18px;
        background: linear-gradient(120deg, var(--color-blue-dark), var(--color-blue-light));
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;

        @media (max-width: breakpoint(tabletPortrait)) {
            font-size: 2.5rem;
        }
    }

    .SectionDescription {
        max-width: 700px;
        font-size: 1.2rem;
        color: #4b5563;
        line-height: 1.6;
    }

    .PartnerCategories {
        margin: 0 auto;
    }

    .PartnerDescription {
        font-size: 1.4rem;
        color: #2d3748;
        line-height: 1.7;
        font-weight: 500;
        margin: 20px 0 30px;
        padding: 50px;
        border-left: 4px solid var(--color-blue-dark);
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

        @media (max-width: breakpoint(tabletLandscape)) {
            font-size: 1.2rem;
        }

        a {
            color: var(--color-blue-dark);
            text-decoration: underline;
        }
    }

    .PartnerCategory {
        margin-bottom: 80px;

        &:last-child {
            margin-bottom: 40px;
        }
    }

    .CategoryHeader {
        margin-bottom: 30px;

        h3 {
            font-weight: 700;
            margin-bottom: 8px;
            color: #2d3748;
        }

        .CategoryDescription {
            font-size: 1.125rem;
            color: #4b5563;
        }
    }

    .TaxameterGrid {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    .TaxameterCard {
        background: white;
        border-radius: 16px;
        padding: 30px;
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        &:hover {
            transform: translateY(-10px);
            box-shadow: 0 15px 35px rgba(var(--color-blue-dark-rgb), 0.15);

            .PartnerImageContainer img {
                filter: none;
            }
        }

        .PartnerImageContainer {
            background: #f8fafc;
            width: 120px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-bottom: 20px;
            overflow: hidden;
            border: 1px solid #e2e8f0;

            img {
                max-width: 80%;
                max-height: 60px;
                filter: grayscale(0.4);
                transition: filter 0.3s ease;
            }
        }

        .TaxameterInfo {
            h4 {
                font-weight: 600;
                margin: 0 0 10px;
                color: #2d3748;
            }

            .TaxameterDescription {
                display: block;
                color: #64748b;
                font-size: 0.95rem;
            }
        }
    }

    .PartnerGrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 20px;
        @media (max-width: breakpoint(mobile)) {
            grid-template-columns: 1fr;
        }
    }

    .PartnerCard {
        background: white;
        border-radius: 16px;
        padding: 30px;
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
        transition: all 0.3s ease;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 100%;
        border: 1px solid #e2e8f0;

        &:hover {
            transform: translateY(-10px);
            box-shadow: 0 15px 35px rgba(var(--color-blue-dark-rgb), 0.15);

            .PartnerImageContainer img {
                filter: none;
                transform: scale(1.05);
            }

            .AppointmentButton {
                background-color: var(--color-blue-dark);
                color: white;

                svg {
                    transform: translateX(5px);
                }
            }
        }

        .PartnerImageContainer {
            height: 80px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
                max-width: 120px;
                filter: grayscale(0.4);
                transition: all 0.3s ease;
            }
        }

        .PartnerContent {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        h4 {
            font-weight: 600;
            margin: 0 0 10px;
            color: #2d3748;
            font-size: 1.4rem;
        }

        p {
            color: #64748b;
            margin-bottom: 20px;
            flex-grow: 1;
            line-height: 1.6;
        }
    }

    .AppointmentButton {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 12px 20px;
        border-radius: 8px;
        text-decoration: none;
        font-weight: 600;
        transition: all 0.3s ease;
        border: 2px solid var(--color-blue-dark);
        color: var(--color-blue-dark);
        align-self: flex-start;

        svg {
            margin-left: 8px;
            transition: transform 0.3s ease;
        }
    }

    .SpecialOfferContainer {
        margin-top: 20px;
    }

    .PromoBenefits {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 20px;

        .PromoBenefit {
            display: flex;
            align-items: center;
            gap: 8px;
            background: rgba(251, 191, 36, 0.15);
            padding: 8px 15px;
            border-radius: 50px;

            i {
                color: #7c2d12;
                font-size: 16px;
            }

            span {
                font-weight: 600;
                color: #7c2d12;
                font-size: 0.9rem;
            }
        }
    }
    .PromoPriceTag {
        position: absolute;
        top: -15px;
        right: -15px;
        background: #ef4444;
        color: white;
        font-weight: 800;
        font-size: 1.2rem;
        padding: 10px 20px;
        border-radius: 50px;
        box-shadow: 0 5px 15px rgba(239, 68, 68, 0.4);
        transform: rotate(15deg);
        z-index: 3;
    }

    .SpecialOffer {
        border-radius: 20px;
        background: linear-gradient(135deg, white 50%, #ffccad);
        overflow: hidden;
        box-shadow: 0 20px 40px rgba(251, 191, 36, 0.15);
        border: 2px solid var(--color-warning);
        position: relative;

        .OfferBadge {
            position: absolute;
            top: 15px;
            right: 15px;
            background: var(--color-warning);
            padding: 8px 18px 8px 12px;
            border-radius: 30px;
            font-size: 0.95rem;
            font-weight: 700;
            color: #7c2d12;
            display: flex;
            align-items: center;
            gap: 4px;
            box-shadow: 0 4px 10px rgba(251, 191, 36, 0.3);
            background: #b03543;
            color: white;

            span {
                font-weight: 800;
                margin-right: 5px;
            }

            @media (max-width: breakpoint(mobile)) {
                position: relative;
                flex-direction: column;
                width: calc(100% - 20px);
                left: 0;
                top: 10px;
                margin: 0 auto;
                border-radius: 16px;
            }
        }

        .OfferContent {
            display: flex;
            padding: 50px;
            align-items: center;

            @media (max-width: breakpoint(tabletLandscape)) {
                flex-direction: column-reverse;
                padding: 40px 20px;
            }
        }

        .OfferInfo {
            flex: 1;
            padding-right: 40px;

            @media (max-width: breakpoint(tabletLandscape)) {
                padding-right: 0;
            }

            .PartnerLogo {
                margin-bottom: 25px;

                img {
                    height: 60px;
                }
            }

            h4 {
                font-weight: 700;
                margin: 0 0 20px;
                color: #2d3748;
                font-size: 1.5rem;
            }

            .OfferDescription {
                margin-bottom: 30px;

                p {
                    color: #4b5563;
                    font-size: 1.1rem;
                    line-height: 1.6;
                    margin-bottom: 20px;
                }
            }

            .BenefitsList {
                list-style: none;
                padding: 0;
                margin: 0 0 30px;

                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 12px;
                    color: #4b5563;
                    font-size: 1rem;

                    svg {
                        color: var(--color-warning);
                        margin-right: 10px;
                        flex-shrink: 0;
                    }

                    @media (max-width: breakpoint(tabletLandscape)) {
                        justify-content: center;
                    }
                }
            }
        }

        .ProductImage {
            flex: 1;
            display: flex;
            justify-content: center;
            position: relative;

            img {
                max-height: 300px;
                filter: drop-shadow(0 20px 30px rgba(0, 0, 0, 0.15));
                transform: perspective(800px) rotateY(-15deg) rotateX(5deg) rotate(5deg);
                transition: transform 0.6s ease-out;

                &:hover {
                    transform: perspective(800px) rotateY(0deg) rotateX(0deg) rotate(0deg);
                }

                @media (max-width: breakpoint(tabletLandscape)) {
                    max-width: 280px;
                    transform: none;
                }
            }
        }
    }
    .LimitedOfferBadge {
        position: absolute;
        top: 15px;
        left: 15px;
        background: #ef4444;
        padding: 8px 18px 8px 12px;
        border-radius: 30px;
        font-size: 0.95rem;
        font-weight: 700;
        color: white;
        display: flex;
        align-items: center;
        gap: 5px;
        box-shadow: 0 4px 10px rgba(239, 68, 68, 0.3);
        z-index: 2;

        svg {
            color: white;
        }
    }

    .SpecialOfferButton {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 16px 30px;
        border-radius: 12px;
        text-decoration: none;
        font-weight: 700;
        transition: all 0.3s ease;
        background-color: var(--color-warning);
        color: #7c2d12;
        font-size: 1.2rem;
        box-shadow: 0 10px 20px rgba(251, 191, 36, 0.3), 0 3px 6px rgba(251, 191, 36, 0.2);

        span {
            margin-right: 10px;
        }

        svg {
            transition: transform 0.3s ease;
        }

        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 15px 25px rgba(251, 191, 36, 0.35), 0 5px 10px rgba(251, 191, 36, 0.2);

            svg {
                transform: translateX(5px);
            }
        }

        @media (max-width: breakpoint(tabletLandscape)) {
            margin: 0 auto;
        }
    }

    .PartnerCTA {
        margin-top: 60px;
        background: linear-gradient(135deg, var(--color-blue-dark), var(--color-blue-light));
        border-radius: 16px;
        padding: 40px;

        color: white;
        box-shadow: 0 15px 30px rgba(var(--color-blue-dark-rgb), 0.2);

        p {
            font-size: 1.3rem;
            margin-bottom: 25px;
            font-weight: 500;
        }

        .PartnershipButton {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 16px 30px;
            border-radius: 12px;
            text-decoration: none;
            font-weight: 700;
            transition: all 0.3s ease;
            background-color: white;
            color: var(--color-blue-dark);
            font-size: 1.1rem;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

            &:hover {
                transform: translateY(-3px);
                box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
                background-color: var(--color-warning);
                color: #7c2d12;
            }
        }
    }
}
</style>
