<template>
    <div class="Section ProblemSolutionSection" id="problem-solution">
        <div class="SectionContainer">
            <div class="SectionHeader">
                <h2 class="SectionTitle">
                    <span class="TitleAccent">Ihre Probleme, unsere Lösungen</span>
                    Für ein effizienteres Taxiunternehmen
                </h2>
            </div>

            <div class="ColumnHeaders">
                <div class="ColumnHeader ProblemHeader">
                    <div class="HeaderIcon">
                        <i class="ri-close-circle-line"></i>
                    </div>
                    <span>Ohne taxiFusion</span>
                </div>
                <div class="ColumnHeader SolutionHeader">
                    <div class="HeaderIcon">
                        <i class="ri-checkbox-circle-fill"></i>
                    </div>
                    <span>Mit taxiFusion</span>
                </div>
            </div>

            <div class="ProblemSolutionGrid">
                <!-- Problem-Solution Pair #1 -->
                <div class="ProblemSolutionPair">
                    <div class="ProblemColumn">
                        <div class="ProblemCard">
                            <div class="ProblemTag">Problem #1</div>
                            <div class="CardIcon">
                                <i class="ri-shield-line" style="font-size: 24px;"></i>
                            </div>
                            <div class="CardContent">
                                <h3>Gesetzliche Vorgaben</h3>
                                <div class="ProblemImpact">
                                    <i class="ri-file-damage-line"></i>
                                    <span>Bußgelder bis zu 5.000€</span>
                                </div>
                                <p>
                                    Ständig wechselnde Anforderungen (INSIKA/TSE) führen zu hohen
                                    Bußgeldern bei Nichteinhaltung.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="ConnectorLine">
                        <div class="ConnectorDot"></div>
                        <div class="ConnectorArrow">
                            <i class="ri-arrow-right-line"></i>
                        </div>
                    </div>

                    <div class="SolutionColumn">
                        <div class="SolutionCard">
                            <div class="SolutionTag">Lösung #1</div>
                            <div class="CardIcon">
                                <i class="ri-shield-check-line" style="font-size: 24px;"></i>
                            </div>
                            <div class="CardContent">
                                <h3>Gesetzeskonform mit taxiFusion</h3>
                                <div class="SolutionImpact">
                                    <i class="ri-shield-check-line"></i>
                                    <span>100% rechtlich abgesichert</span>
                                </div>
                                <p>
                                    taxiFusion hält Sie stets aktuell mit TSE-Anforderungen – ohne
                                    zusätzlichen Aufwand für Sie.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Problem-Solution Pair #2 -->
                <div class="ProblemSolutionPair">
                    <div class="ProblemColumn">
                        <div class="ProblemCard">
                            <div class="ProblemTag">Problem #2</div>
                            <div class="CardIcon">
                                <i class="ri-admin-line" style="font-size: 24px;"></i>
                            </div>
                            <div class="CardContent">
                                <h3>Aufwändige Verwaltung</h3>
                                <div class="ProblemImpact">
                                    <i class="ri-timer-line"></i>
                                    <span>15+ Stunden/Woche verschwendet</span>
                                </div>
                                <p>
                                    Zeitverschwendung durch komplizierte Software, die mehr Probleme
                                    verursacht als löst.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="ConnectorLine">
                        <div class="ConnectorDot"></div>
                        <div class="ConnectorArrow">
                            <i class="ri-arrow-right-line"></i>
                        </div>
                    </div>

                    <div class="SolutionColumn">
                        <div class="SolutionCard">
                            <div class="SolutionTag">Lösung #2</div>
                            <div class="CardIcon">
                                <i class="ri-dashboard-line" style="font-size: 24px;"></i>
                            </div>
                            <div class="CardContent">
                                <h3>Einfache Verwaltung mit taxiFusion</h3>
                                <div class="SolutionImpact">
                                    <i class="ri-timer-line"></i>
                                    <span>15+ Stunden/Woche gespart</span>
                                </div>
                                <p>
                                    taxiFusion bietet intuitive Software, die Ihre
                                    Verwaltungsaufgaben vereinfacht und automatisiert.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Problem-Solution Pair #3 -->
                <div class="ProblemSolutionPair">
                    <div class="ProblemColumn">
                        <div class="ProblemCard">
                            <div class="ProblemTag">Problem #3</div>
                            <div class="CardIcon">
                                <i class="ri-time-line" style="font-size: 24px;"></i>
                            </div>
                            <div class="CardContent">
                                <h3>Hohe versteckte Kosten</h3>
                                <div class="ProblemImpact">
                                    <i class="ri-money-euro-circle-line"></i>
                                    <span>Bis zu 2.500€ Mehrkosten/Jahr</span>
                                </div>
                                <p>
                                    Verpasste Fristen für P-Scheine und wichtige Dokumente führen zu
                                    hohen finanziellen Einbußen.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="ConnectorLine">
                        <div class="ConnectorDot"></div>
                        <div class="ConnectorArrow">
                            <i class="ri-arrow-right-line"></i>
                        </div>
                    </div>

                    <div class="SolutionColumn">
                        <div class="SolutionCard">
                            <div class="SolutionTag">Lösung #3</div>
                            <div class="CardIcon">
                                <i class="ri-money-euro-circle-line" style="font-size: 24px;"></i>
                            </div>
                            <div class="CardContent">
                                <h3>Intelligente Erinnerungen mit taxiFusion</h3>
                                <div class="SolutionImpact">
                                    <i class="ri-money-euro-circle-line"></i>
                                    <span>Bis zu 2.500€ gespart/Jahr</span>
                                </div>
                                <p>
                                    taxiFusion sendet intelligente Benachrichtigungen für P-Scheine,
                                    TÜV und mehr, um Ihre Einnahmen zu sichern.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- CTA Card from Solution.vue -->
            <div class="SolutionCTACard">
                <div class="CTACardContent">
                    <div class="CTACardHeader">
                        <div class="CTAIcon">
                            <i class="ri-rocket-line"></i>
                        </div>
                        <h3>Starten Sie Ihr effizienteres Taxiunternehmen</h3>
                    </div>

                    <div class="CTACardBody">
                        <p>
                            <span class="Highlight">taxiFusion</span> ist mehr als nur eine
                            Verpflichtung zur Datenspeicherung. Unser Ziel ist es, Ihnen die
                            Werkzeuge an die Hand zu geben, die Sie benötigen, um Ihr Geschäft
                            effizienter und rentabler zu gestalten.
                        </p>
                        <router-link to="/register">
                            <Button size="large">
                                Jetzt Registrieren <i class="ri-arrow-right-line"></i>
                            </Button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/widgets/Button.vue';

export default {
    name: 'ProblemSolutionSection',
    components: {
        Button,
    },
};
</script>

<style lang="scss">
.ProblemSolutionSection {
    padding: 100px 0 150px;
    position: relative;
    overflow: hidden;
    @extend %sectionWrapper;

    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 100px 0 70px;
    }

    .SectionContainer {
        @extend %sectionWrapper;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        padding: 0 20px;
    }

    .SectionHeader {
        margin-bottom: 60px;
        position: relative;
        text-align: center;

        .HeaderBadge {
            display: inline-block;
            background-color: rgba(45, 55, 72, 0.1);
            color: #2d3748;
            padding: 8px 20px;
            border-radius: 100px;
            font-weight: 600;
            font-size: 0.95rem;
            margin-bottom: 20px;
        }

        .SectionTitle {
            font-size: 3rem;
            font-weight: 800;
            color: #2d3748;
            margin: 0 0 30px;
            line-height: 1.2;

            .TitleAccent {
                display: block;
                margin-bottom: 5px;
                color: #0e6c4b;
            }

            @media (max-width: breakpoint(tabletPortrait)) {
                font-size: 2.4rem;
            }

            @media (max-width: breakpoint(mobile)) {
                font-size: 2rem;
            }
        }
    }

    .ColumnHeaders {
        display: flex;
        justify-content: space-around;
        margin-bottom: 50px;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        gap: 140px;
        position: relative;

        @media (max-width: breakpoint(tabletLandscape)) {
            display: none;
        }

        .ColumnHeader {
            display: flex;
            align-items: center;
            padding: 12px 30px;
            border-radius: 100px;
            font-weight: 700;
            font-size: 1.1rem;

            z-index: 2;
            min-width: 200px;
            justify-content: center;

            .HeaderIcon {
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 24px;
                }
            }
        }

        .ProblemHeader {
            background-color: rgba(75, 85, 99, 0.1);
            color: #4b5563;

            .HeaderIcon {
                i {
                    color: #4b5563;
                }
            }
        }

        .SolutionHeader {
            background-color: rgba(16, 185, 129, 0.18);
            color: #0e6c4b;

            .HeaderIcon {
                i {
                    color: #0e6c4b;
                }
            }
        }
    }

    .ProblemSolutionGrid {
        display: flex;
        flex-direction: column;
        gap: 80px;
        margin-bottom: 70px;

        @media (max-width: breakpoint(tabletLandscape)) {
            gap: 120px;
        }
    }

    .ProblemSolutionPair {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 30px;

        @media (max-width: breakpoint(tabletLandscape)) {
            flex-direction: column;
            gap: 25px;
            align-items: center;
        }
    }

    .ProblemColumn,
    .SolutionColumn {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 500px;
        width: 450px;

        @media (max-width: breakpoint(tabletLandscape)) {
            max-width: 100%;
            width: 100%;
        }
    }

    .ColumnLabel {
        display: flex;
        align-items: center;
        padding: 8px 20px;
        border-radius: 100px;
        font-weight: 700;
        font-size: 1rem;
        margin-bottom: 30px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
        background-color: rgba(75, 85, 99, 0.08);
        color: #4b5563;

        .LabelIcon {
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 20px;
                color: #4b5563;
            }
        }
    }

    .SolutionLabel {
        background-color: rgba(16, 185, 129, 0.15);
        color: #0e6c4b;

        .LabelIcon {
            i {
                font-size: 20px;
                color: #0e6c4b;
            }
        }
    }

    .ConnectorLine {
        position: relative;
        width: 80px;
        height: 4px;
        background: linear-gradient(90deg, #4b5563, #0e6c4b);
        flex-shrink: 0;
        margin-top: 100px;
        z-index: 1;

        @media (max-width: breakpoint(tabletLandscape)) {
            width: 4px;
            height: 40px;
            background: linear-gradient(180deg, #4b5563, #0e6c4b);
            margin-top: 0;
            margin-bottom: 30px;
            align-self: center;
        }

        .ConnectorDot {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 12px;
            height: 12px;
            background-color: #4b5563;
            border-radius: 50%;

            @media (max-width: breakpoint(tabletLandscape)) {
                left: 50%;
                top: 0;
                transform: translateX(-50%);
            }
        }

        .ConnectorArrow {
            position: absolute;
            right: -10px;
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            background-color: #0e6c4b;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;

            @media (max-width: breakpoint(tabletLandscape)) {
                right: auto;
                left: 50%;
                top: auto;
                bottom: -10px;
                transform: translateX(-50%) rotate(90deg);
            }
        }
    }

    .ProblemCard,
    .SolutionCard {
        background: white;
        border-radius: 16px;
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        border: 1px solid #e2e8f0;
        width: 100%;

        .CardIcon {
            width: 70px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            border-radius: 20px;
            margin: -35px auto 15px;
            box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
            transition: all 0.3s ease;
            z-index: 2;
            border: 5px solid white;
        }

        .CardContent {
            padding: 5px 25px 30px;

            h3 {
                font-size: 1.5rem;
                font-weight: 700;
                margin: 0 0 15px;
            }

            p {
                font-size: 1rem;
                margin: 0 0 15px;
                line-height: 1.6;
            }
        }
    }

    .ProblemCard {
        .ProblemTag {
            position: absolute;
            top: 15px;
            right: 15px;
            background-color: rgba(75, 85, 99, 0.1);
            color: #4b5563;
            padding: 5px 12px;
            border-radius: 20px;
            font-size: 0.8rem;
            font-weight: 700;
            letter-spacing: 0.5px;
        }

        .CardIcon {
            background-color: #767f8b;
        }

        .CardContent {
            h3 {
                color: #4b5563;
            }

            p {
                color: #6b7280;
            }
        }

        .ProblemImpact {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            background-color: rgba(75, 85, 99, 0.08);
            padding: 8px 15px;
            border-radius: 8px;
            margin-bottom: 15px;

            i {
                color: #4b5563;
            }

            span {
                font-weight: 600;
                color: #4b5563;
                font-size: 0.9rem;
            }
        }

        &:hover {
            transform: translateY(-10px);
            box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15);
            border-color: #4b5563;

            .CardIcon {
                background-color: #374151;
            }
        }
    }

    .SolutionCard {
        .SolutionTag {
            position: absolute;
            top: 15px;
            right: 15px;
            background-color: rgba(16, 185, 129, 0.15);
            color: #0e6c4b;
            padding: 5px 12px;
            border-radius: 20px;
            font-size: 0.8rem;
            font-weight: 700;
            letter-spacing: 0.5px;
        }

        .CardIcon {
            background-color: #0e6c4b;
        }

        .CardContent {
            h3 {
                color: #2d3748;
            }

            p {
                color: #4a5568;
            }
        }

        .SolutionImpact {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            background-color: rgba(16, 185, 129, 0.15);
            padding: 8px 15px;
            border-radius: 8px;
            margin-bottom: 15px;

            i {
                color: #0e6c4b;
            }

            span {
                font-weight: 600;
                color: #0e6c4b;
                font-size: 0.9rem;
            }
        }

        &:hover {
            transform: translateY(-10px);
            box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15);
            border-color: #0e6c4b;

            .CardIcon {
                background-color: #0a5539;
            }
        }
    }

    /* CTA Card Styles from Solution.vue */
    .SolutionCTACard {
        margin-top: 80px;
        background: linear-gradient(135deg, #ffffff 0%, #f7fafc 100%);
        border-radius: 20px;
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
        border: 1px solid #e2e8f0;
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 6px;
            background: linear-gradient(90deg, #0e6c4b, #10b981 100%);
        }

        .CTACardContent {
            padding: 40px;

            @media (max-width: breakpoint(tabletPortrait)) {
                padding: 30px 25px;
            }
        }

        .CTACardHeader {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            @media (max-width: breakpoint(mobile)) {
                flex-direction: column;
            }

            .CTAIcon {
                flex: 0 0 60px;
                height: 60px;
                background: linear-gradient(135deg, #0e6c4b 0%, #10b981 100%);
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 20px;
                box-shadow: 0 10px 20px rgba(16, 185, 129, 0.25);

                @media (max-width: breakpoint(mobile)) {
                    margin: 0 auto 20px;
                }

                i {
                    font-size: 28px;
                    color: white;
                }
            }

            h3 {
                font-size: 1.8rem;
                font-weight: 700;
                color: #2d3748;
                margin: 0;
                line-height: 1.3;

                @media (max-width: breakpoint(mobile)) {
                    font-size: 1.5rem;
                }
            }
        }

        .CTACardBody {
            color: #4a5568;
            font-size: 1.1rem;
            line-height: 1.7;

            p {
                margin-bottom: 30px;
            }

            .Highlight {
                font-weight: 800;
                color: #0e6c4b;
            }
        }
    }
}
</style>
