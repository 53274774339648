var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Section SolutionSection",
    attrs: {
      "id": "solution"
    }
  }, [_c('div', {
    staticClass: "SectionContainer"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "SolutionCTACard"
  }, [_c('div', {
    staticClass: "CTACardContent"
  }, [_vm._m(2), _c('div', {
    staticClass: "CTACardBody"
  }, [_vm._m(3), _c('router-link', {
    attrs: {
      "to": "/register"
    }
  }, [_c('Button', {
    attrs: {
      "size": "large"
    }
  }, [_vm._v(" Jetzt Registrieren "), _c('i', {
    staticClass: "ri-arrow-right-line"
  })])], 1)], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SectionHeader"
  }, [_c('div', {
    staticClass: "HeaderBadge"
  }, [_vm._v("Unsere Lösung")]), _c('h2', {
    staticClass: "SectionTitle"
  }, [_c('span', {
    staticClass: "TitleAccent"
  }, [_vm._v("Entdecken Sie taxiFusion")]), _vm._v(" Optimiert für Taxiunternehmer ")]), _c('div', {
    staticClass: "SuccessBar"
  }, [_c('i', {
    staticClass: "ri-check-line"
  }), _c('p', [_vm._v(" Mit taxiFusion sparen Sie Zeit, reduzieren Kosten und bleiben gesetzlich konform ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SolutionGrid"
  }, [_c('div', {
    staticClass: "SolutionCard"
  }, [_c('div', {
    staticClass: "SolutionTag"
  }, [_vm._v("Lösung #1")]), _c('div', {
    staticClass: "CardIcon"
  }, [_c('i', {
    staticClass: "ri-shield-check-line",
    staticStyle: {
      "font-size": "24px"
    }
  })]), _c('div', {
    staticClass: "CardContent"
  }, [_c('h3', [_vm._v("Gesetzeskonform")]), _c('div', {
    staticClass: "SolutionImpact"
  }, [_c('i', {
    staticClass: "ri-shield-check-line"
  }), _c('span', [_vm._v("100% rechtlich abgesichert")])]), _c('p', [_vm._v(" Stets aktuell mit TSE-Anforderungen – ohne zusätzlichen Aufwand für Sie. ")])])]), _c('div', {
    staticClass: "SolutionCard"
  }, [_c('div', {
    staticClass: "SolutionTag"
  }, [_vm._v("Lösung #2")]), _c('div', {
    staticClass: "CardIcon"
  }, [_c('i', {
    staticClass: "ri-dashboard-line",
    staticStyle: {
      "font-size": "24px"
    }
  })]), _c('div', {
    staticClass: "CardContent"
  }, [_c('h3', [_vm._v("Einfache Verwaltung")]), _c('div', {
    staticClass: "SolutionImpact"
  }, [_c('i', {
    staticClass: "ri-timer-line"
  }), _c('span', [_vm._v("15+ Stunden/Woche gespart")])]), _c('p', [_vm._v(" Intuitive Software, die Ihre Verwaltungsaufgaben vereinfacht und automatisiert. ")])])]), _c('div', {
    staticClass: "SolutionCard"
  }, [_c('div', {
    staticClass: "SolutionTag"
  }, [_vm._v("Lösung #3")]), _c('div', {
    staticClass: "CardIcon"
  }, [_c('i', {
    staticClass: "ri-money-euro-circle-line",
    staticStyle: {
      "font-size": "24px"
    }
  })]), _c('div', {
    staticClass: "CardContent"
  }, [_c('h3', [_vm._v("Erinnerungen")]), _c('div', {
    staticClass: "SolutionImpact"
  }, [_c('i', {
    staticClass: "ri-money-euro-circle-line"
  }), _c('span', [_vm._v("Bis zu 2.500€ gespart/Jahr")])]), _c('p', [_vm._v(" Intelligente Benachrichtigungen für P-Scheine, TÜV und mehr sichern Ihre Einnahmen. ")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "CTACardHeader"
  }, [_c('div', {
    staticClass: "CTAIcon"
  }, [_c('i', {
    staticClass: "ri-rocket-line"
  })]), _c('h3', [_vm._v("Starten Sie Ihr effizienteres Taxiunternehmen")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('span', {
    staticClass: "Highlight"
  }, [_vm._v("taxiFusion")]), _vm._v(" ist mehr als nur eine Verpflichtung zur Datenspeicherung. Unser Ziel ist es, Ihnen die Werkzeuge an die Hand zu geben, die Sie benötigen, um Ihr Geschäft effizienter und rentabler zu gestalten. ")]);
}]

export { render, staticRenderFns }