var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ContactSection",
    attrs: {
      "id": "contact"
    }
  }, [_vm._m(0), _vm._m(1), _vm.hasSendMessage ? _c('div', {
    ref: "messageSend",
    staticClass: "ContactContainer"
  }, [_c('div', {
    staticClass: "SuccessMessage"
  }, [_vm._m(2), _c('h2', [_vm._v("Ihre Nachricht wurde erfolgreich gesendet")]), _c('p', [_vm._v(" Vielen Dank für Ihre Kontaktaufnahme! Wir werden uns schnellstmöglich bei Ihnen melden. ")]), _c('div', {
    staticClass: "SuccessActions"
  }, [_c('button', {
    staticClass: "ResetButton",
    on: {
      "click": _vm.resetForm
    }
  }, [_c('i', {
    staticClass: "ri-mail-line"
  }), _vm._v(" Neue Nachricht senden ")])])])]) : _c('div', {
    staticClass: "ContactContainer"
  }, [_c('div', {
    staticClass: "ContactContent"
  }, [_vm._m(3), _c('div', {
    staticClass: "ContactFormWrapper"
  }, [_c('form', {
    staticClass: "ContactForm",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "FormRow"
  }, [_c('div', {
    staticClass: "InputGroup"
  }, [_c('label', {
    attrs: {
      "for": "name"
    }
  }, [_vm._v("Ihr Name")]), _c('div', {
    staticClass: "InputWithIcon"
  }, [_c('i', {
    staticClass: "ri-user-line"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    attrs: {
      "type": "text",
      "id": "name",
      "placeholder": "Vollständiger Name",
      "required": ""
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "InputGroup"
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("E-Mail Adresse")]), _c('div', {
    staticClass: "InputWithIcon"
  }, [_c('i', {
    staticClass: "ri-mail-line"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    attrs: {
      "type": "email",
      "id": "email",
      "placeholder": "name@beispiel.de",
      "required": ""
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "InputGroup"
  }, [_c('label', {
    attrs: {
      "for": "message"
    }
  }, [_vm._v("Ihre Nachricht")]), _c('div', {
    staticClass: "TextareaWrapper"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.message,
      expression: "form.message"
    }],
    attrs: {
      "id": "message",
      "placeholder": "Wie können wir Ihnen helfen?",
      "required": ""
    },
    domProps: {
      "value": _vm.form.message
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "message", $event.target.value);
      }, _vm.updateCharacterCount]
    }
  }), _c('div', {
    staticClass: "CharacterCounter",
    class: {
      'is-valid': _vm.characterCount >= _vm.minCharacterCount
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.characterCount) + "/" + _vm._s(_vm.minCharacterCount))]), _c('div', {
    staticClass: "ProgressBar"
  }, [_c('div', {
    staticClass: "Progress",
    style: {
      width: "".concat(Math.min(_vm.characterCount / _vm.minCharacterCount * 100, 100), "%")
    }
  })])])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.cat,
      expression: "form.cat"
    }],
    staticClass: "honeypot",
    attrs: {
      "type": "text",
      "name": "cat",
      "aria-hidden": "true"
    },
    domProps: {
      "value": _vm.form.cat
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "cat", $event.target.value);
      }
    }
  }), _c('Button', {
    staticClass: "SubmitButton",
    attrs: {
      "type": "submit",
      "size": "large",
      "isLoading": _vm.isSending,
      "disabled": _vm.characterCount < _vm.minCharacterCount
    }
  }, [_vm._v(" Nachricht senden "), _c('i', {
    staticClass: "ri-send-plane-line"
  })])], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SectionBackground"
  }, [_c('div', {
    staticClass: "BackgroundPattern"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ContactHeadline"
  }, [_c('h2', {
    staticClass: "MainHeadline"
  }, [_vm._v("Fragen zu taxiFusion?")]), _c('p', {
    staticClass: "SubHeadline"
  }, [_vm._v(" Wir sind für Sie da und helfen Ihnen gerne bei allen Anliegen rund um taxiFusion ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SuccessIcon"
  }, [_c('i', {
    staticClass: "ri-check-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ContactInfo"
  }, [_c('div', {
    staticClass: "SectionHeader"
  }, [_c('h2', [_vm._v("Wir freuen uns auf Ihre Nachricht")]), _c('p', [_vm._v(" Haben Sie Fragen zu taxiFusion? Unser Team steht Ihnen gerne zur Verfügung. ")])]), _c('div', {
    staticClass: "ContactDetails"
  }, [_c('div', {
    staticClass: "ContactItem"
  }, [_c('div', {
    staticClass: "ContactItemIcon"
  }, [_c('i', {
    staticClass: "ri-mail-line"
  })]), _c('div', {
    staticClass: "ContactItemContent"
  }, [_c('h3', [_vm._v("E-Mail")]), _c('a', {
    attrs: {
      "href": "mailto:info@taxifusion.de"
    }
  }, [_vm._v("info@taxifusion.de")])])]), _c('div', {
    staticClass: "ContactItem"
  }, [_c('div', {
    staticClass: "ContactItemIcon"
  }, [_c('i', {
    staticClass: "ri-phone-line"
  })]), _c('div', {
    staticClass: "ContactItemContent"
  }, [_c('h3', [_vm._v("Telefon")]), _c('a', {
    attrs: {
      "href": "tel:++493076006505"
    }
  }, [_vm._v("+49 30 76 00 65 05")])])]), _c('div', {
    staticClass: "ContactItem"
  }, [_c('div', {
    staticClass: "ContactItemIcon"
  }, [_c('i', {
    staticClass: "ri-time-line"
  })]), _c('div', {
    staticClass: "ContactItemContent"
  }, [_c('h3', [_vm._v("Erreichbarkeit")]), _c('p', [_vm._v("Dienstag: 14:00 - 16:00 Uhr")]), _c('p', [_vm._v("Donnerstag: 16:00 - 19:00 Uhr")])])])])]);
}]

export { render, staticRenderFns }