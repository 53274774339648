<template>
    <footer class="Footer">
        <div class="FooterContainer">
            <div class="FooterTop">
                <div class="BrandSection">
                    <router-link to="/" class="Logo">
                        <img src="../assets/svg/logo.svg" alt="TaxiFusion Logo" />
                    </router-link>
                    <p class="BrandTagline">Die moderne Lösung für Taxiunternehmer</p>
                    <div class="SocialIcons">
                        <a href="#" aria-label="Facebook" class="SocialIcon">
                            <i class="ri-facebook-fill"></i>
                        </a>
                        <a href="#" aria-label="Twitter" class="SocialIcon">
                            <i class="ri-twitter-fill"></i>
                        </a>
                        <a href="#" aria-label="LinkedIn" class="SocialIcon">
                            <i class="ri-linkedin-fill"></i>
                        </a>
                    </div>
                </div>

                <nav class="FooterNavigation">
                    <div class="NavCol">
                        <h3>Produkt</h3>
                        <ul>
                            <li><a href="/#features">Features</a></li>
                            <li><a href="/#pricing">Preise</a></li>
                            <li><a href="/sign-in">Login</a></li>
                        </ul>
                    </div>

                    <div class="NavCol">
                        <h3>taxiFusion</h3>
                        <ul>
                            <li>
                                <router-link to="/privacy-policy">Datenschutzerklärung</router-link>
                            </li>
                            <li>
                                <router-link to="/terms-of-service"
                                    >Geschäftsbedingungen</router-link
                                >
                            </li>
                            <li><router-link to="/impressum">Impressum</router-link></li>
                            <li><a href="/#contact">Kontakt</a></li>
                        </ul>
                    </div>

                    <div class="NavCol ContactCol">
                        <h3>Kontakt</h3>
                        <div class="ContactInfo">
                            <div class="ContactItem">
                                <i class="ri-mail-line"></i>
                                <a href="mailto:info@taxifusion.de">info@taxifusion.de</a>
                            </div>
                            <div class="ContactItem">
                                <i class="ri-phone-line"></i>
                                <a href="tel:+493076006505">+49 30 76 00 65 05</a>
                            </div>
                            <div class="ContactItem">
                                <i class="ri-map-pin-line"></i>
                                <a
                                    href="https://maps.google.com/?q=Buckower+Chaussee+110-113,+12277+Berlin"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Buckower Chaussee 110-113<br />
                                    12277 Berlin
                                </a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>

            <div class="FooterBottom">
                <div class="Copyright">
                    <p>&copy; {{ currentYear }} taxiFusion. Alle Rechte vorbehalten.</p>
                </div>
                <div class="LegalLinks">
                    <router-link to="/privacy-policy">Datenschutz</router-link>
                    <span class="Divider">|</span>
                    <router-link to="/terms-of-service">AGB</router-link>
                    <span class="Divider">|</span>
                    <router-link to="/impressum">Impressum</router-link>
                </div>
            </div>
        </div>

        <div class="FooterBackground">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
                preserveAspectRatio="none"
            >
                <path
                    fill="rgba(255,255,255,0.03)"
                    d="M0,224L60,213.3C120,203,240,181,360,181.3C480,181,600,203,720,224C840,245,960,267,1080,266.7C1200,267,1320,245,1380,234.7L1440,224L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
                ></path>
            </svg>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'SiteFooter',
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
        isLandingPage() {
            return this.$route.path === '/';
        },
    },
};
</script>

<style lang="scss">
.Footer {
    padding: 100px 0 30px;
    color: var(--color-text-white);
    background: linear-gradient(135deg, #474e7a 0%, #161936 100%);
    position: relative;
    overflow: hidden;

    .FooterBackground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 0;

        svg {
            position: absolute;
            top: 0;
            width: 100%;
            height: 350px;
        }
    }

    .FooterContainer {
        @extend %sectionWrapper;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
        position: relative;
        z-index: 1;
    }

    .FooterTop {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 40px;
        margin-bottom: 60px;

        @media (max-width: breakpoint(tabletPortrait)) {
            flex-direction: column;
            gap: 50px;
            margin-bottom: 40px;
        }
    }

    .BrandSection {
        max-width: 300px;

        .Logo {
            display: flex;
            align-items: center;
            text-decoration: none;
            margin-bottom: 20px;

            img {
                width: 50px;
                height: 50px;
            }

            .LogoText {
                color: white;
                font-size: 24px;
                font-weight: 700;
                margin-left: 12px;
                letter-spacing: 0.5px;
            }
        }

        .BrandTagline {
            color: rgba(255, 255, 255, 0.8);
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 25px;
        }

        .SocialIcons {
            display: flex;
            gap: 15px;
            margin-top: 20px;

            .SocialIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                background-color: rgba(255, 255, 255, 0.1);
                border-radius: 50%;
                color: white;
                text-decoration: none;
                transition: all 0.3s ease;

                i {
                    font-size: 18px;
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.2);
                    transform: translateY(-3px);
                }
            }
        }
    }

    .FooterNavigation {
        display: flex;
        flex-wrap: wrap;
        gap: 60px;

        @media (max-width: breakpoint(tabletPortrait)) {
            width: 100%;
            gap: 40px;
        }

        @media (max-width: breakpoint(mobile)) {
            flex-direction: column;
            gap: 30px;
        }

        .NavCol {
            min-width: 170px;

            @media (max-width: breakpoint(mobile)) {
                width: 100%;
            }

            h3 {
                color: white;
                font-size: 18px;
                font-weight: 700;
                margin: 0 0 20px;
                letter-spacing: 0.5px;
                position: relative;
                padding-bottom: 12px;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 30px;
                    height: 2px;
                    background-color: rgba(255, 255, 255, 0.3);
                }
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    margin-bottom: 12px;

                    a {
                        color: rgba(255, 255, 255, 0.8);
                        text-decoration: none;
                        font-size: 15px;
                        transition: all 0.2s ease;
                        display: inline-block;
                        position: relative;
                        padding-left: 0;

                        &:before {
                            content: '';
                            position: absolute;
                            bottom: -2px;
                            left: 0;
                            width: 0;
                            height: 1px;
                            background-color: white;
                            transition: width 0.3s ease;
                        }

                        &:hover {
                            color: white;
                            padding-left: 5px;

                            &:before {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .ContactCol {
            min-width: 220px;

            .ContactInfo {
                .ContactItem {
                    display: flex;
                    margin-bottom: 15px;

                    i {
                        color: rgba(255, 255, 255, 0.6);
                        font-size: 18px;
                        width: 24px;
                        margin-right: 10px;
                    }

                    a,
                    span {
                        color: rgba(255, 255, 255, 0.8);
                        font-size: 15px;
                        text-decoration: none;
                        transition: color 0.2s ease;
                    }

                    a:hover {
                        color: white;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .FooterBottom {
        padding-top: 30px;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;

        @media (max-width: breakpoint(mobile)) {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        .Copyright {
            p {
                margin: 0;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.6);
            }
        }

        .LegalLinks {
            display: flex;
            align-items: center;
            gap: 10px;

            a {
                color: rgba(255, 255, 255, 0.6);
                text-decoration: none;
                font-size: 14px;
                transition: color 0.2s ease;

                &:hover {
                    color: white;
                }
            }

            .Divider {
                color: rgba(255, 255, 255, 0.3);
                font-size: 12px;
            }
        }
    }
}
</style>
