<template>
    <div class="App">
        <div
            class="PromotionBar"
            v-if="showPromotion && !isPromotionClosed"
            :class="{ 'PromotionBar--bottom': isMobile }"
        >
            <div class="PromotionContent">
                <i class="ri-coupon-2-line"></i>
                <span>
                    <b>SONDERAKTION:</b> 50% Rabatt auf alle taxiFusion Tarife + myPOS GO 2 Terminal
                    GRATIS bis 30. April 2025
                </span>
                <a href="#pricing" class="PromotionButton">Angebot sichern</a>
                <button
                    v-if="isMobile"
                    class="PromotionCloseButton"
                    @click="closePromotion"
                    aria-label="Promotion schließen"
                >
                    <i class="ri-close-line"></i>
                </button>
            </div>
        </div>
        <SiteHeader />
        <HeroSection />
        <!-- <ProblemSolution />
        <Solution /> -->
        <ProblemSolution />
        <!-- <Credentials /> -->
        <Features />

        <!-- <Testimonials /> -->
        <!-- <CTA size="large">
            <h2>
                <span>Benötigen Sie ein Taxameter?</span>
                <br />
                <br />
                Nutzen Sie <a target="_blank" href="https://digitax-berlin.de/">Digitax</a>, unser
                Partner des Vertrauens
            </h2>

            <p>
                Wir arbeiten eng mit zertifizierten Herstellern von Fiskaltaxametern zusammen.
                Dadurch sind wir in der Lage Ihnen Geräte anzubieten, die speziell auf unser System
                abgepasst sind. Und das beste: Sie kriegen die Geräte auch gleich bei uns.
                <br />
                <br />
                Bei Fragen rufen Sie uns einfach an. Wir beraten Sie gern!
            </p>
        </CTA> -->
        <Pricing />
        <Partner />

        <Contact />
        <!-- <Founders /> -->

        <SiteFooter />
    </div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader';
import HeroSection from './Landingpage/HeroSection';
import Solution from './Landingpage/Solution';
import Credentials from './Landingpage/Credentials';
import Partner from './Landingpage/Partner';
import Features from './Landingpage/Features';
import CTA from './Landingpage/CTA.vue';
import SiteFooter from '@/components/SiteFooter';
import Pricing from './Landingpage/Pricing.vue';
import Contact from './Landingpage/Contact.vue';
import Testimonials from './Landingpage/Testimonials.vue';
import ProblemAwareness from './Landingpage/ProblemAwareness';
import ProblemSolution from './Landingpage/ProblemSolution';
import { gsap } from 'gsap';
// import Founders from './Landingpage/Founders.vue';

export default {
    name: 'landing',
    components: {
        SiteHeader,
        HeroSection,
        ProblemAwareness,
        ProblemSolution,
        Solution,
        Credentials,
        Partner,
        Features,
        SiteFooter,
        // CTA,
        Pricing,
        Contact,
        // Testimonials,
        // Founders,
    },

    data() {
        return {
            isLoggedIn: false,
            isMobile: false,
            isPromotionClosed: false,
            windowWidth: window.innerWidth,
        };
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
            this.isMobile = window.innerWidth <= 768;
        },
        closePromotion() {
            // Animate the closing for a nicer effect
            const promotionBar = document.querySelector('.PromotionBar');
            gsap.to(promotionBar, {
                height: 0,
                opacity: 0,
                paddingTop: 0,
                paddingBottom: 0,
                duration: 0.3,
                onComplete: () => {
                    setTimeout(() => {
                        this.isPromotionClosed = true;
                    }, 100);
                    // Store in localStorage to keep it closed if user refreshes
                    localStorage.setItem('promotionClosed', 'true');
                },
            });
        },
        checkStoredPromotionState() {
            // Check if user has previously closed the promotion
            const isClosed = localStorage.getItem('promotionClosed') === 'true';
            this.isPromotionClosed = isClosed;
        },
    },
    computed: {
        showPromotion() {
            return new Date() < new Date('2025-04-30T23:59:59');
        },
    },
    mounted() {
        document.querySelectorAll('a').forEach(a => {
            if (a.href.includes('#')) {
                a.addEventListener('click', function(e) {
                    e.preventDefault();
                    document.querySelector(a.getAttribute('href')).scrollIntoView({
                        behavior: 'smooth',
                    });
                });
            }
        });

        // Check device size on mount
        this.handleResize();

        // Add resize listener
        window.addEventListener('resize', this.handleResize);

        // Check if promotion was previously closed
        this.checkStoredPromotionState();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>
<style lang="scss">
body {
    width: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    font-size: 18px;
    color: var(--color-text-black);
    background-color: var(--color-bg);
    letter-spacing: 0.5px;

    .App {
        position: relative;

        line-height: 1.85em;
        font-family: font(outfit);

        .Wrapper {
            max-width: 1440px;
            padding: 0 40px;
            box-sizing: border-box;
            margin: 0 auto;
        }
    }
    @media (max-width: 1200px) {
        font-size: 16px;
    }
    @media (max-width: 500px) {
        font-size: 14px;
    }
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
*:focus {
    outline: none;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.Column {
    display: flex;
    gap: 10px;

    > * {
        flex: 1;
    }
}

.PromotionBar {
    width: 100%;
    background-color: #b03543;
    padding: 10px 0;
    color: white;
    z-index: 250; // Higher than header but lower than mobile menu
    position: fixed;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    top: 0;
    transition: all 0.3s ease;
    overflow: hidden; // For smooth animation when closing

    &--bottom {
        top: auto;
        bottom: 0;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding: 12px 0;
    }

    .PromotionContent {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
        position: relative; // For absolute positioning of close button

        i {
            font-size: 24px;
            flex-shrink: 0;
        }

        span {
            font-size: 1rem;
        }

        .PromotionButton {
            background-color: white;
            color: #b03543;
            padding: 5px 15px;
            border-radius: 50px;
            font-weight: 700;
            font-size: 0.9rem;
            text-decoration: none;
            transition: all 0.3s ease;
            white-space: nowrap;
            flex-shrink: 0;

            &:hover {
                background-color: #f3f4f6;
                transform: translateY(-2px);
            }
        }

        .PromotionCloseButton {
            background: rgba(0, 0, 0, 0.15);
            border: none;
            color: white;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 0;
            transition: all 0.2s ease;
            flex-shrink: 0;

            i {
                font-size: 20px;
            }

            &:hover {
                background: rgba(0, 0, 0, 0.25);
            }

            &:active {
                transform: scale(0.95);
            }
        }

        @media (max-width: 768px) {
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 10px;
            padding: 0 15px;

            span {
                flex-basis: 100%;
                order: 1;
                padding-right: 30px; // Space for close button
            }

            i {
                order: 0;
                margin-top: 2px;
            }

            .PromotionButton {
                order: 2;
                margin-bottom: 5px;
            }

            .PromotionCloseButton {
                position: absolute;
                top: 0;
                right: 15px;
            }
        }
    }
}
</style>
