<template>
    <div class="Section Features" id="features">
        <h2>Wir <span>vereinfachen</span> alle Ihre Aufgaben</h2>
        <div class="Content">
            <div class="Feature">
                <div class="Text">
                    <h3>Die Taxisoftware von morgen</h3>
                    <div class="VideoWrap">
                        <video
                            class="Video"
                            autoplay
                            muted
                            controls
                            playsinline
                            ref="featureVideo"
                            poster="/product/dashboard.jpg"
                        >
                            <source src="/product/tf-demo.mp4" type="video/mp4" />
                        </video>
                    </div>

                    <p>
                        Umfassende Managementlösung, die speziell für die Anforderungen und Abläufe
                        von Taxiunternehmen entwickelt wurde. Durch die intuitive Benutzeroberfläche
                        und leistungsfähige Analysewerkzeuge können Sie Ihren Betrieb effizienter
                        und transparenter gestalten.
                        <br /><br />
                        Die Plattform integriert alle notwendigen Funktionen, von der
                        Fahrzeugverfolgung bis hin zur Umsatzanalyse, in einem einzigen, leicht
                        zugänglichen System.
                    </p>
                </div>
            </div>
            <!-- <div class="Feature">
<div class="Text">
<h3>Überall zugänglich</h3>
<img src="/product/revenue.png" alt="" />

<p>
<b>taxiFusion</b> nutzt die Kraft der Cloud-Technologie, um eine
zuverlässige, internetbasierte Verwaltungslösung anzubieten. Mit dieser
Plattform können Sie von überall aus auf Ihr Geschäft zugreifen, am PC oder
vom Handy aus, was maximale Flexibilität und Kontrolle ermöglicht.
<br />
<br />
Die Sicherheit Ihrer Daten wird durch moderne Verschlüsselungstechniken
gewährleistet, während Sie gleichzeitig von der ständigen Verfügbarkeit und
Aktualität der Software profitieren.
</p>
</div>
</div>
<div class="Feature">
<div class="Text">
<h3>Einhaltung gesetzlicher Anforderungen</h3>
<img src="/product/worktime.png" alt="" />

<p>
Wir sorgen dafür, dass Ihr Taxiunternehmen alle gesetzlichen Anforderungen
erfüllt, was die administrative Last erheblich reduziert.
<br />
<br />
Die Plattform unterstützt Sie bei der Einhaltung von Vorschriften, sei es
bei der Konzessionsverwaltung oder der Sicherstellung der
Datenschutzstandards. Mit uns können Sie sich darauf verlassen, dass Ihr
Betrieb stets den neuesten gesetzlichen Richtlinien entspricht.
</p>
</div>
</div>
<div class="Feature">
<div class="Text">
<h3>Über 30 Jahre Erfahrung</h3>
<img src="/product/shifts.png" alt="" />

<p>
Das System wurde mit direktem Input und Erfahrungen von Taxiunternehmern und
-fahrern entwickelt, um sicherzustellen, dass es den branchenspezifischen
Bedürfnissen entspricht.
<br />
<br />
Die Features und Funktionen sind auf die einzigartigen Herausforderungen und
Chancen im Taxigewerbe zugeschnitten.
</p>
</div>
</div> -->

            <h2 class="Features-Title">Auf einem <span>Blick</span></h2>
            <div class="Features-Grid">
                <div class="Feature-Item">
                    <i class="ri-focus-line"></i>
                    <h3>Übersicht über Ihr Unternehmen</h3>
                    <p>
                        Erhalten Sie detaillierte Einblicke und Analysen über Ihr Unternehmen, um
                        fundierte Entscheidungen zu treffen.
                    </p>
                </div>

                <div class="Feature-Item">
                    <i class="ri-smartphone-line"></i>
                    <h3>Integrierte Fahrer-App</h3>
                    <p>
                        Ermöglichen Sie Ihren Fahrern den Zugriff auf Fahrtdaten und Optimierung der
                        Routen direkt über ihr Smartphone.
                    </p>
                </div>

                <div class="Feature-Item">
                    <i class="ri-cloud-line"></i>
                    <h3>Internetbasierte Verwaltung</h3>
                    <p>
                        Verwalten Sie Ihr Geschäft von überall aus mit einer Cloud-Plattform, die
                        Ihnen maximale Flexibilität bietet.
                    </p>
                </div>

                <div class="Feature-Item">
                    <i class="ri-shield-check-line"></i>
                    <h3>Erfüllung gesetzlicher Anforderungen</h3>
                    <p>
                        Bleiben Sie immer auf dem neuesten Stand der gesetzlichen Bestimmungen und
                        vermeiden Sie rechtliche Probleme.
                    </p>
                </div>
                <div class="Feature-Item">
                    <i class="ri-brain-line"></i>
                    <h3>Über 30 Jahre Erfahrung</h3>
                    <p>
                        Das System wurde mit direktem Input und Erfahrungen von Taxiunternehmern und
                        -fahrern entwickelt, um sicherzustellen, dass es den Bedürfnissen der Kunden
                        entspricht.
                    </p>
                </div>

                <!-- <div class="Feature-Item">
<i class="ri-book-line"></i>
<h3>Fahrtenbuch für die Finanzbehörde</h3>
<p>
Automatisieren Sie die Aufzeichnung Ihrer Fahrten für eine unkomplizierte
Abrechnung und Transparenz gegenüber den Finanzbehörden.
</p>
</div> -->

                <!-- <div class="Feature-Item">
<i class="ri-calendar-todo-line"></i>
<h3>Terminplaner</h3>
<p>
Planen und verwalten Sie alle anstehenden Termine und Wartungen, um den
Betrieb Ihrer Flotte reibungslos zu halten.
</p>
</div> -->

                <div class="Feature-Item">
                    <i class="ri-pie-chart-line"></i>
                    <h3>Advanced Analyse</h3>
                    <p>
                        Greifen Sie auf fortgeschrittene Analysetools zu, um die Leistung Ihres
                        Unternehmens genau zu verstehen und zu verbessern.
                    </p>
                </div>

                <!-- <div class="Feature-Item">
<i class="ri-time-line"></i>
<h3>Schichtplaner</h3>
<p>
Organisieren Sie die Arbeitszeiten Ihrer Fahrer effizient und stellen Sie
sicher, dass Ihr Betrieb zu jeder Zeit optimal läuft.
</p>
</div> -->

                <!-- <div class="Feature-Item">
<i class="ri-tools-line"></i>
<h3>Fahrzeug Reparatur Historie</h3>
<p>
Verfolgen Sie die Wartungs- und Reparaturhistorie jedes Fahrzeugs, um
langfristig Kosten zu sparen und die Sicherheit zu gewährleisten.
</p>
</div> -->
            </div>

            <div class="Feature Feature--App">
                <img src="/product/mobile.png" alt="" />
                <div class="Text">
                    <h3>Die App für den Fahrer</h3>
                    <p>
                        Die Fahrer-App ermöglicht es den Fahrern, auf wichtige Informationen
                        zuzugreifen und ihre Fahrten effizient zu verwalten. Mit
                        Echtzeit-Statistiken und Zugriff auf Betriebsdaten können Fahrer ihre
                        Leistung verbessern. Die App ist benutzerfreundlich gestaltet und bietet den
                        Fahrern eine nahtlose Integration in das Gesamtsystem.
                    </p>

                    <div class="AppStoreIcons">
                        <a
                            href="https://apps.apple.com/de/app/taxifusion-driver-app/id6523420111?l=en-GB"
                            target="_blank"
                            ref="noopener noreferrer"
                        >
                            <img
                                src="/app-store-icon.png"
                                alt="Apple App Store"
                                height="50"
                                style="border : 1px solid white; border-radius: 10px;"
                            />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.taxifusion.driverApp"
                            target="_blank"
                            ref="noopener noreferrer"
                        >
                            <img
                                src="/google-play-icon.png"
                                alt="Google Play Store"
                                height="50"
                                style="border : 1px solid white; border-radius: 10px;"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        const video = this.$refs.featureVideo;
        video.playbackRate = 0.5;
    },
};
</script>

<style lang="scss">
.Features {
    background: linear-gradient(-42deg, color(blue-light) 0%, color(blue-dark) 100%);
    padding: 100px 40px 250px;
    margin-bottom: 100px;
    box-sizing: border-box;
    color: var(--color-text-white);

    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 100px 20px 0;
        margin-bottom: 0px;

        overflow: hidden;
    }

    p {
        line-height: 2;
    }

    h2 {
        font-weight: 200;
        color: white;
        text-align: center;

        @media (max-width: breakpoint(tabletPortrait)) {
            text-align: left;
        }

        span {
            font-weight: 700;
        }
    }

    .Content {
        padding-top: 100px;
        max-width: 1250px;
        margin: 0 auto;

        @media (max-width: breakpoint(tabletPortrait)) {
            padding-top: 50px;
        }

        .Feature {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            gap: 60px;
            padding-bottom: 180px;
            padding-top: 180px;

            &.Feature--App {
                flex-direction: row;
                padding-bottom: 120px;

                .Text {
                    margin-top: 120px;
                }

                h3,
                p {
                    width: unset;
                    margin: 0;
                }

                p {
                    font-size: inherit;
                    line-height: 2;
                }

                img {
                    margin-top: 0;
                    margin-left: 0;
                    width: 100%;
                    max-width: 500px;
                    margin-bottom: -220px;
                    @media (max-width: breakpoint(tabletLandscape)) {
                        margin-bottom: 90px;
                    }
                }

                @media (max-width: breakpoint(tabletLandscape)) {
                    flex-direction: column;

                    img {
                        margin-top: 0;
                        margin-left: 0;
                        width: 100%;
                        max-width: 600px;
                    }
                    .Text {
                        margin-left: 0;
                        // font-size: 16px;
                        margin-top: -200px;
                        padding-bottom: 80px;
                    }
                }
            }

            @media (max-width: breakpoint(tabletPortrait)) {
                padding-bottom: 80px;
                padding-top: 80px;
            }

            &:first-child {
                padding-top: 0;
            }

            &:nth-child(odd) {
                .Text {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                }

                @media (max-width: breakpoint(tabletPortrait)) {
                    text-align: left;
                    flex-direction: column;
                    .Text {
                        padding-left: 0px;
                        align-items: flex-start;
                    }
                }
            }

            &:nth-child(even) {
                // .Text {
                // }
                @media (max-width: breakpoint(tabletPortrait)) {
                    text-align: left;
                    flex-direction: column;
                    .Text {
                        padding-right: 0px;
                        align-items: flex-start;
                    }
                }
            }

            &:last-child {
                padding-bottom: 0;
                z-index: 2;
                position: relative;
                margin-bottom: -260px;

                @media (max-width: breakpoint(tabletLandscape)) {
                    margin-bottom: 0;
                }

                .Text {
                    @media (max-width: breakpoint(tabletPortrait)) {
                        padding-left: 0;
                    }
                }

                img {
                    box-shadow: none;
                }
            }

            h3 {
                font-weight: 700;
                margin-bottom: 30px;
                color: white;
            }

            .AppStoreIcons {
                display: flex;
                gap: 20px;
                margin-top: 30px;

                @media (max-width: breakpoint(tabletPortrait)) {
                    flex-direction: column;
                    a > img {
                        width: 70%;
                        height: auto;
                        max-width: 170px;
                        margin-bottom: 0px;
                    }
                }

                a {
                    display: inline-block;
                }
            }
            img {
                max-width: 100%;
                display: block;
                box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
                border-radius: 10px;
                margin-bottom: 30px;

                @media (max-width: breakpoint(tabletPortrait)) {
                    width: 800px;
                    max-width: unset;
                    margin-top: 30px;
                    margin-bottom: 80px;
                }
            }

            p {
                color: var(--color-text-white);
            }
        }

        h3,
        p {
            width: 800px;
            margin: 0 auto;

            @media (max-width: breakpoint(tabletPortrait)) {
                width: 80vw;
                margin: 0;
            }
        }
    }

    .VideoWrap {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        margin-bottom: 30px;
        width: 100%;

        @media (max-width: breakpoint(tabletPortrait)) {
            width: 80vw;
            margin: 0;
        }

        .Video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }

    .Features-Title {
        margin-top: 4rem;
        margin-bottom: 2rem;
    }

    .Features-Grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        max-width: 1000px;
        margin: 0 auto;

        @media (max-width: breakpoint(tabletLandscape)) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: breakpoint(tabletPortrait)) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .Feature-Item {
        border-radius: 8px;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        text-align: left;

        @media (max-width: breakpoint(tabletPortrait)) {
            padding: 2rem 0;
        }

        h3 {
            font-size: 1.25rem;
            font-weight: 700;
            margin: 0;
            margin-bottom: 1rem;
            color: #ffffff;
            width: auto;
        }

        p {
            margin: 0;
            width: auto;
            font-size: 1rem;
            color: #ffffff;
        }

        i {
            font-size: 2rem; /* Larger icons */
            color: #ffffff; /* Theme color for icons */
            margin-bottom: 1rem;
        }
    }
}
</style>
