<template>
    <div class="Section Credentials">
        <div class="Col">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 1l8.217 1.826a1 1 0 01.783.976v9.987a6 6 0 01-2.672 4.992L12 23l-6.328-4.219A6 6 0 013 13.79V3.802a1 1 0 01.783-.976L12 1zm4.452 7.222l-4.95 4.949-2.828-2.828-1.414 1.414L11.503 16l6.364-6.364-1.415-1.414z"/></svg>
            <h4>Staatlich Anerkannt</h4>
            <p>
                Wir erfüllen stets die aktuellen Voraussetzungen der wichtigsten Behörden der
                Taxibranche!
            </p>
        </div>
        <div class="Col">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.005 20h-4v2h-2v-2h-1a1 1 0 01-1-1V4a1 1 0 011-1h7V1.59a.5.5 0 01.582-.493L21.17 2.86a1 1 0 01.836.987V6h1v2h-1v7h1v2h-1v2.152a1 1 0 01-.836.987l-1.164.194V22h-2v-1.334l-7.418 1.237a.5.5 0 01-.582-.494V20zm2-.361l8-1.334V4.694l-8-1.333v16.278zm4.5-5.64c-.828 0-1.5-1.119-1.5-2.5 0-1.38.671-2.5 1.5-2.5.828 0 1.5 1.12 1.5 2.5 0 1.381-.672 2.5-1.5 2.5z"/></svg>
            <h4>Aktuelle Sicherheit</h4>
            <p>
                Seit 2017 sind wir an Ihrer Seite und bieten Ihnen eine entsprechende Lösung zur
                Datenspeicherung für Ihr Fiskaltaxameter an.
            </p>
        </div>
        <div class="Col">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17 7a8.003 8.003 0 00-7.493 5.19l1.873.703A6.002 6.002 0 0123 15a6 6 0 01-6 6H7A6 6 0 015.008 9.339a7 7 0 0113.757-2.143A8.027 8.027 0 0017 7z"/></svg>
            <h4>Überall zugreifen</h4>
            <p>
                Unsere webbasierte Software ermöglicht es Ihnen, Ihr Unternehmen von überall aus zu
                verwalten. Auf dem PC, Smartphone oder Tablet.
            </p>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.Credentials {
    @extend %sectionWrapper;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 78px 40px 85px;

    @media (max-width: breakpoint(tabletPortrait)) {
        gap: 10px;
        flex-direction: column;
        padding: 60px 20px;
    }

    .Col {
        flex: 1;
        line-height: 40px;
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        background-color: white;
        border-radius: 8px;
        padding: 30px;

        svg {
            width: 50px;
            height: 50px;
            margin-left: 10px;
            fill: var(--color-blue-dark);
        }

        h4 {
            padding-top: 20px;
            margin: 0;
            font-weight: 700;
            font-size: 26px;
            padding-bottom: 5px;
        }

        p {
            color: color(text-black);
            font-size: 20px;
        }
    }
}
</style>
