<template>
    <div class="Dashboard">
        <AppNavigation />
        <Skeleton v-if="!hasFinishedLoading" />
        <div class="Wrap">
            <Infobox
                v-if="!userVerified"
                :hasCloseButton="false"
                title="Email Bestätigen"
                message="Sie haben eine Bestätigungs-E-Mail erhalten. Bitte klicken Sie auf den Link in dieser E-Mail, um Ihre Email-Adresse zu bestätigen."
            >
                <div class="EmailVerifyActions">
                    <Button
                        variant="plain"
                        :isLoading="isLoadingVerifyEmail"
                        @onClick="handleVerifyEmailClick"
                        size="small"
                    >
                        Email Ändern
                    </Button>
                    <Button
                        size="small"
                        :isLoading="isLoadingVerifyEmail"
                        @onClick="handleVerifyEmailClick"
                    >
                        Email Erneut versenden
                    </Button>
                </div>
            </Infobox>
            <transition-group
                :name="transitionName"
                as="div"
                mode="out-in"
                style="display:block;overflow: auto;height:100vh;position: relative;"
            >
                <router-view @onFinishLoading="handleFinishedLoading" key="2" />
            </transition-group>
        </div>
        <Modal
            :show="isModalOpen"
            size="small"
            :title="'Hallo ' + contactPerson"
            :action="{ text: 'Los geht\'s', callback: closeModal }"
            :cancel="{ text: 'Abbrechen', callback: closeModal }"
            @onModalClose="closeModal"
        >
            <p>
                Willkommen in Ihrem neuen Dashboard ✨
                <br />
                <br />
                TaxiFusion hat nun ein neues Aussehen. Doch auch unter der Haube hat sich einiges
                getan! Neben einem schnelleren System haben wir auch neue Funktionen eingeführt. So
                können Sie Falschanmeldungen z.B. nun selbst korrigieren. 📝
                <br /><br />
                Auch eine neue Fahrer-App 📱 ist nun verfügbar! Diese finden Sie
                <a
                    href="https://play.google.com/store/apps/details?id=com.taxifusion.driverApp"
                    target="_blank"
                    rel="noopener noreferrer"
                    style="color: var(--color-blue-light);text-decoration: underline;"
                >
                    hier</a
                >
                für Android. <br /><br />
                Ihr TaxiFusion-Team 🙋🏻‍♂️
            </p>
        </Modal>
    </div>
</template>
<script>
import 'remixicon/fonts/remixicon.css';
import { mapActions, mapGetters } from 'vuex';
import 'floating-vue/dist/style.css';
import AppNavigation from '@/components/AppNavigation.vue';
import Skeleton from '@/components/Skeleton.vue';
import Modal from '@/components/widgets/Modal.vue';
import Infobox from '@/components/widgets/Infobox.vue';
import Button from '@/components/widgets/Button.vue';
import axios from 'axios';

const DEFAULT_TRANSITION = 'fade';

export default {
    name: 'dashboard',
    components: {
        AppNavigation,

        Skeleton,
        Modal,
        Infobox,
        Button,
    },
    watch: {
        $route() {
            this.hasFinishedLoading = false;
        },
    },
    data() {
        return {
            profile: {},
            isModalOpen: false,
            transitionName: DEFAULT_TRANSITION,
            hasFinishedLoading: false,
            isLoadingVerifyEmail: false,
        };
    },
    created() {
        this.$router.beforeEach((to, from, next) => {
            let transitionName = to.meta.transitionName || from.meta.transitionName;
            if (transitionName === 'slide') {
                const toDepth = to.path.split('/').length;
                const fromDepth = from.path.split('/').length;
                transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
            }

            this.transitionName = transitionName || DEFAULT_TRANSITION;
            next();
        });
    },
    methods: {
        ...mapActions(['getProfile']),
        async handleVerifyEmailClick() {
            console.log('verify');
            try {
                this.isLoadingVerifyEmail = true;

                await axios.post(
                    `${process.env.VUE_APP_API_BASE_URL}/cpanel/profile/email/request-verify`,
                    null,
                    {
                        withCredentials: true,
                    },
                );
                this.$toasted.success('Email wurden gesendet');
                this.isLoadingVerifyEmail = false;
            } catch (e) {
                console.log(e);
                this.isLoadingVerifyEmail = false;
            }
        },
        handleFinishedLoading() {
            setTimeout(() => {
                this.hasFinishedLoading = true;
            }, 200);
        },
        closeModal() {
            this.isModalOpen = false;
            localStorage.setItem('isFirstLogin', 'false');
        },
    },
    computed: {
        ...mapGetters(['contactPerson', 'userVerified', 'user']),
    },
    async mounted() {
        const theme = localStorage.getItem('theme') || 'light';
        document.body.classList.add(`theme-${theme}`);
        try {
            await this.getProfile();
            this.isModalOpen = localStorage.getItem('isFirstLogin') === 'true';
        } catch (error) {
            this.$toasted.show('Ihre Sitzung ist abgelaufen. Bitte melden Sie sich wieder an', {
                type: 'error',
            });
            console.log(error);
            this.$router.push('/sign-in');
            localStorage.setItem('drivers', JSON.stringify([]));
            localStorage.setItem('cars', JSON.stringify([]));
        }
    },
};
</script>
<style lang="scss">
.Dashboard {
    /* total width */
    ::-webkit-scrollbar {
        background-color: var(--color-white);
        width: 10px;
        height: 10px;
    }

    /* background of the scrollbar except button or resizer */
    ::-webkit-scrollbar-track {
        background-color: var(--color-white);
    }

    /* scrollbar itself */
    ::-webkit-scrollbar-thumb {
        background-color: var(--color-blue-light);
        border-radius: 16px;
        width: 10px;
        border: 4px solid var(--color-white);
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
        display: none;
    }

    overflow: hidden;
    display: grid;
    grid-template-columns: [sidebar] 220px [main-content] 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
        'sidebar main-content'
        'sidebar sitefooter';
    column-gap: 20px;
    position: relative;
    height: 100vh;
    will-change: auto;

    @media screen and (max-width: breakpoint(tabletPortrait)) {
        display: flex;
        flex-direction: column;
    }

    h1 {
        line-height: 1.5;
    }

    .Wrap {
        overflow: auto;
    }

    .Infobox {
        .EmailVerifyActions {
            margin-top: 10px;
            gap: 4px;

            @media (max-width: breakpoint(tabletLandscape)) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    .about {
        @extend %contentWrapper;
        margin-top: 100px;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0;
        will-change: transform;
    }

    .slide-left-enter-active,
    .slide-left-leave-active,
    .slide-right-enter-active,
    .slide-right-leave-active {
        transition-duration: 0.65s;
        transition-property: opacity, transform;
        transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
        overflow: hidden;
    }

    .slide-left-enter,
    .slide-right-leave-active {
        opacity: 0;
        transform: translate(20px, 0) translateZ(0);
    }

    .slide-left-leave-active,
    .slide-right-enter {
        opacity: 0;
        transform: translate(-20px, 0) translateZ(0);
    }
}
</style>
