var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Section ProblemSolutionSection",
    attrs: {
      "id": "problem-solution"
    }
  }, [_c('div', {
    staticClass: "SectionContainer"
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _c('div', {
    staticClass: "SolutionCTACard"
  }, [_c('div', {
    staticClass: "CTACardContent"
  }, [_vm._m(3), _c('div', {
    staticClass: "CTACardBody"
  }, [_vm._m(4), _c('router-link', {
    attrs: {
      "to": "/register"
    }
  }, [_c('Button', {
    attrs: {
      "size": "large"
    }
  }, [_vm._v(" Jetzt Registrieren "), _c('i', {
    staticClass: "ri-arrow-right-line"
  })])], 1)], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SectionHeader"
  }, [_c('h2', {
    staticClass: "SectionTitle"
  }, [_c('span', {
    staticClass: "TitleAccent"
  }, [_vm._v("Ihre Probleme, unsere Lösungen")]), _vm._v(" Für ein effizienteres Taxiunternehmen ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ColumnHeaders"
  }, [_c('div', {
    staticClass: "ColumnHeader ProblemHeader"
  }, [_c('div', {
    staticClass: "HeaderIcon"
  }, [_c('i', {
    staticClass: "ri-close-circle-line"
  })]), _c('span', [_vm._v("Ohne taxiFusion")])]), _c('div', {
    staticClass: "ColumnHeader SolutionHeader"
  }, [_c('div', {
    staticClass: "HeaderIcon"
  }, [_c('i', {
    staticClass: "ri-checkbox-circle-fill"
  })]), _c('span', [_vm._v("Mit taxiFusion")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ProblemSolutionGrid"
  }, [_c('div', {
    staticClass: "ProblemSolutionPair"
  }, [_c('div', {
    staticClass: "ProblemColumn"
  }, [_c('div', {
    staticClass: "ProblemCard"
  }, [_c('div', {
    staticClass: "ProblemTag"
  }, [_vm._v("Problem #1")]), _c('div', {
    staticClass: "CardIcon"
  }, [_c('i', {
    staticClass: "ri-shield-line",
    staticStyle: {
      "font-size": "24px"
    }
  })]), _c('div', {
    staticClass: "CardContent"
  }, [_c('h3', [_vm._v("Gesetzliche Vorgaben")]), _c('div', {
    staticClass: "ProblemImpact"
  }, [_c('i', {
    staticClass: "ri-file-damage-line"
  }), _c('span', [_vm._v("Bußgelder bis zu 5.000€")])]), _c('p', [_vm._v(" Ständig wechselnde Anforderungen (INSIKA/TSE) führen zu hohen Bußgeldern bei Nichteinhaltung. ")])])])]), _c('div', {
    staticClass: "ConnectorLine"
  }, [_c('div', {
    staticClass: "ConnectorDot"
  }), _c('div', {
    staticClass: "ConnectorArrow"
  }, [_c('i', {
    staticClass: "ri-arrow-right-line"
  })])]), _c('div', {
    staticClass: "SolutionColumn"
  }, [_c('div', {
    staticClass: "SolutionCard"
  }, [_c('div', {
    staticClass: "SolutionTag"
  }, [_vm._v("Lösung #1")]), _c('div', {
    staticClass: "CardIcon"
  }, [_c('i', {
    staticClass: "ri-shield-check-line",
    staticStyle: {
      "font-size": "24px"
    }
  })]), _c('div', {
    staticClass: "CardContent"
  }, [_c('h3', [_vm._v("Gesetzeskonform mit taxiFusion")]), _c('div', {
    staticClass: "SolutionImpact"
  }, [_c('i', {
    staticClass: "ri-shield-check-line"
  }), _c('span', [_vm._v("100% rechtlich abgesichert")])]), _c('p', [_vm._v(" taxiFusion hält Sie stets aktuell mit TSE-Anforderungen – ohne zusätzlichen Aufwand für Sie. ")])])])])]), _c('div', {
    staticClass: "ProblemSolutionPair"
  }, [_c('div', {
    staticClass: "ProblemColumn"
  }, [_c('div', {
    staticClass: "ProblemCard"
  }, [_c('div', {
    staticClass: "ProblemTag"
  }, [_vm._v("Problem #2")]), _c('div', {
    staticClass: "CardIcon"
  }, [_c('i', {
    staticClass: "ri-admin-line",
    staticStyle: {
      "font-size": "24px"
    }
  })]), _c('div', {
    staticClass: "CardContent"
  }, [_c('h3', [_vm._v("Aufwändige Verwaltung")]), _c('div', {
    staticClass: "ProblemImpact"
  }, [_c('i', {
    staticClass: "ri-timer-line"
  }), _c('span', [_vm._v("15+ Stunden/Woche verschwendet")])]), _c('p', [_vm._v(" Zeitverschwendung durch komplizierte Software, die mehr Probleme verursacht als löst. ")])])])]), _c('div', {
    staticClass: "ConnectorLine"
  }, [_c('div', {
    staticClass: "ConnectorDot"
  }), _c('div', {
    staticClass: "ConnectorArrow"
  }, [_c('i', {
    staticClass: "ri-arrow-right-line"
  })])]), _c('div', {
    staticClass: "SolutionColumn"
  }, [_c('div', {
    staticClass: "SolutionCard"
  }, [_c('div', {
    staticClass: "SolutionTag"
  }, [_vm._v("Lösung #2")]), _c('div', {
    staticClass: "CardIcon"
  }, [_c('i', {
    staticClass: "ri-dashboard-line",
    staticStyle: {
      "font-size": "24px"
    }
  })]), _c('div', {
    staticClass: "CardContent"
  }, [_c('h3', [_vm._v("Einfache Verwaltung mit taxiFusion")]), _c('div', {
    staticClass: "SolutionImpact"
  }, [_c('i', {
    staticClass: "ri-timer-line"
  }), _c('span', [_vm._v("15+ Stunden/Woche gespart")])]), _c('p', [_vm._v(" taxiFusion bietet intuitive Software, die Ihre Verwaltungsaufgaben vereinfacht und automatisiert. ")])])])])]), _c('div', {
    staticClass: "ProblemSolutionPair"
  }, [_c('div', {
    staticClass: "ProblemColumn"
  }, [_c('div', {
    staticClass: "ProblemCard"
  }, [_c('div', {
    staticClass: "ProblemTag"
  }, [_vm._v("Problem #3")]), _c('div', {
    staticClass: "CardIcon"
  }, [_c('i', {
    staticClass: "ri-time-line",
    staticStyle: {
      "font-size": "24px"
    }
  })]), _c('div', {
    staticClass: "CardContent"
  }, [_c('h3', [_vm._v("Hohe versteckte Kosten")]), _c('div', {
    staticClass: "ProblemImpact"
  }, [_c('i', {
    staticClass: "ri-money-euro-circle-line"
  }), _c('span', [_vm._v("Bis zu 2.500€ Mehrkosten/Jahr")])]), _c('p', [_vm._v(" Verpasste Fristen für P-Scheine und wichtige Dokumente führen zu hohen finanziellen Einbußen. ")])])])]), _c('div', {
    staticClass: "ConnectorLine"
  }, [_c('div', {
    staticClass: "ConnectorDot"
  }), _c('div', {
    staticClass: "ConnectorArrow"
  }, [_c('i', {
    staticClass: "ri-arrow-right-line"
  })])]), _c('div', {
    staticClass: "SolutionColumn"
  }, [_c('div', {
    staticClass: "SolutionCard"
  }, [_c('div', {
    staticClass: "SolutionTag"
  }, [_vm._v("Lösung #3")]), _c('div', {
    staticClass: "CardIcon"
  }, [_c('i', {
    staticClass: "ri-money-euro-circle-line",
    staticStyle: {
      "font-size": "24px"
    }
  })]), _c('div', {
    staticClass: "CardContent"
  }, [_c('h3', [_vm._v("Intelligente Erinnerungen mit taxiFusion")]), _c('div', {
    staticClass: "SolutionImpact"
  }, [_c('i', {
    staticClass: "ri-money-euro-circle-line"
  }), _c('span', [_vm._v("Bis zu 2.500€ gespart/Jahr")])]), _c('p', [_vm._v(" taxiFusion sendet intelligente Benachrichtigungen für P-Scheine, TÜV und mehr, um Ihre Einnahmen zu sichern. ")])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "CTACardHeader"
  }, [_c('div', {
    staticClass: "CTAIcon"
  }, [_c('i', {
    staticClass: "ri-rocket-line"
  })]), _c('h3', [_vm._v("Starten Sie Ihr effizienteres Taxiunternehmen")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('span', {
    staticClass: "Highlight"
  }, [_vm._v("taxiFusion")]), _vm._v(" ist mehr als nur eine Verpflichtung zur Datenspeicherung. Unser Ziel ist es, Ihnen die Werkzeuge an die Hand zu geben, die Sie benötigen, um Ihr Geschäft effizienter und rentabler zu gestalten. ")]);
}]

export { render, staticRenderFns }