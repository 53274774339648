<template>
    <div class="Section Pricing" id="pricing">
        <div class="SectionHeader">
            <p class="Subtitle">
                Spezielle Tarife für Ihre Taxiflotte.
            </p>
            <h2 class="Headline">
                Flexible Lösungen
            </h2>
        </div>
        <div class="PromotionBanner" v-if="showPromotion">
            <div class="PromotionIcon">
                <i class="ri-alarm-warning-fill"></i>
            </div>
            <div class="PromotionContent">
                <h3>Sonderaktion: Nur bis 30. April 2025!</h3>
                <p>
                    50% Rabatt auf alle Tarife + myPOS Go 2 Terminal kostenlos
                </p>
            </div>
        </div>
        <div class="HeaderInfo">
            <p class="SetupFee">
                <i class="ri-settings-line Icon"></i> Es fallen 49€ einmalige Einrichtungsgebühr an.
            </p>
            <p class="TaxInfo">
                <i class="ri-information-line Icon"></i> Alle Preise verstehen sich zzgl. MwSt.
            </p>
        </div>

        <div class="Price-Wrap">
            <Card class="Price-Card Price-Card--highlight">
                <div class="PlanTag PlanTag--blue">Günstigster Preis in Deutschland</div>
                <div class="PlanLabel">Lite</div>
                <div class="PlanDescription">
                    Gesetzliche Anforderungen erfüllen
                </div>
                <h3 class="PlanPrice">
                    <div v-if="showPromotion">
                        <span class="OldPrice">10€</span>
                        5€
                    </div>
                    <div v-else>
                        10€
                    </div>
                    <span class="Info"> pro Monat / pro Taxi</span>
                </h3>
                <div class="PromotionNote" v-if="showPromotion">
                    <i class="ri-time-line"></i> Angebot gültig bis 30. April 2025
                </div>
                <ul class="FeatureList">
                    <li><span>Datenspeicherung in Echtzeit</span></li>
                    <li><span>SIM-Karte ohne Vertragsbindung</span></li>
                    <li><span>TSE Gateway Gebühren inklusive</span></li>
                    <li><span>Mitarbeiterverwaltung</span></li>
                    <li><span>Schichtmanagement</span></li>
                </ul>
                <p class="PromotionFeature" v-if="showPromotion">
                    <i class="ri-checkbox-circle-line"></i>
                    <span>GRATIS myPOS Go 2 Terminal</span>
                </p>
                <div class="PlanCta">
                    <router-link to="/register" class="Button" v-if="showPromotion"
                        >Aktionsangebot sichern</router-link
                    >
                    <router-link to="/register" class="Button" v-else>Auswählen</router-link>
                </div>
            </Card>

            <Card class="Price-Card" v-if="false">
                <div class="PlanLabel">Basic</div>

                <div class="PlanDescription">
                    Alleinfahrende Einzelunternehmer
                </div>
                <h3 class="PlanPrice">
                    <div v-if="showPromotion">
                        <span class="OldPrice">13€</span>
                        6,50€
                    </div>
                    <div v-else>
                        13€
                    </div>
                    <span class="Info"> pro Monat / pro Taxi</span>
                </h3>
                <div class="PromotionNote" v-if="showPromotion">
                    <i class="ri-time-line"></i> Angebot gültig bis 30. April 2025
                </div>
                <ul class="FeatureList">
                    <li><span>Alles in Lite</span></li>
                    <li><span>Mitarbeiterverwaltung</span></li>
                    <li><span>Einzelfahrtenaufzeichnung</span></li>
                    <li><span>Schichtmanagement</span></li>

                    <li><span>Betriebsnachweise</span></li>
                    <li><span>Arbeitszeitnachweise</span></li>
                </ul>
                <p class="PromotionFeature" v-if="showPromotion">
                    <i class="ri-checkbox-circle-line"></i>
                    <span>GRATIS myPOS Go 2 Terminal</span>
                </p>
                <div class="PlanCta">
                    <router-link to="/register" class="Button" v-if="showPromotion"
                        >Aktionsangebot sichern</router-link
                    >
                    <router-link to="/register" class="Button" v-else>Auswählen</router-link>
                </div>
            </Card>

            <Card class="Price-Card">
                <div class="PlanLabel">Basic</div>

                <div class="PlanDescription">
                    Optimal für Unternehmen mit mehreren Fahrzeugen
                </div>
                <h3 class="PlanPrice">
                    <div v-if="showPromotion">
                        <span class="OldPrice">16€</span>
                        8€
                    </div>
                    <div v-else>
                        16€
                    </div>
                    <span class="Info"> pro Monat / pro Taxi</span>
                </h3>
                <div class="PromotionNote" v-if="showPromotion">
                    <i class="ri-time-line"></i> Angebot gültig bis 30. April 2025
                </div>
                <ul class="FeatureList">
                    <li><span>Alles in Lite</span></li>
                    <li><span>Betriebsnachweise</span></li>
                    <li><span>Arbeitszeitnachweise</span></li>
                    <li>
                        <b><span>Integrierte taxiFusion Driver App</span></b>
                        <ul class="SubFeatureList">
                            <li><span>Pausenzeiten </span></li>
                            <li><span>Fahrtmarkierungen und Anpassungen </span></li>
                            <li><span>Statistiken und Auswertungen</span></li>
                        </ul>
                    </li>
                </ul>
                <p class="PromotionFeature" v-if="showPromotion">
                    <i class="ri-checkbox-circle-line"></i>
                    <span>GRATIS myPOS Go 2 Terminal</span>
                </p>

                <div class="DiscountInfo">
                    <span class="DiscountLabel">Rabatte</span>
                    <ul>
                        <li>Für Einzelfahrer <span class="OldPrice">13€</span> 6,50€</li>
                        <li>Ab 10 Autos <span class="OldPrice">15,20€</span> 7,60€</li>
                        <li>Ab 20 Autos <span class="OldPrice">14,40€</span> 7,20€</li>
                    </ul>
                </div>
                <div class="PlanCta">
                    <router-link to="/register" class="Button" v-if="showPromotion"
                        >Aktionsangebot sichern</router-link
                    >
                    <router-link to="/register" class="Button" v-else>Auswählen</router-link>
                </div>
                <!-- <div class="DiscountInfo DiscountInfo-Einzelfahrer">
                    <span class="DiscountLabel">Einzelfahrerrabatt</span>
                    <p class="DiscountText">
                        3€ Rabatt für Einzelfahrer
                    </p>
                </div> -->
            </Card>
        </div>

        <div class="Modules">
            <h4 class="ModulesTitle">
                Zusatzfunktionen für Ihr Geschäft.
            </h4>
            <div class="ModulesWrap">
                <Card class="ModuleCard">
                    <div class="ModuleIcon"><i class="ri-alarm-warning-line"></i></div>
                    <h5>Erinnerungsfunktionen</h5>
                    <p>
                        Erinnerungen für Reifen und Ölwechsel, Inspektionen, TÜV,
                        P-Schein-Verlängerung und mehr. Verpassen Sie nie wieder wichtige Termine.
                    </p>
                </Card>
                <Card class="ModuleCard">
                    <div class="ModuleIcon"><i class="ri-bar-chart-2-line"></i></div>
                    <h5>Performance-Analyse</h5>
                    <p>
                        Detaillierte Einblicke in die Leistung Ihrer Fahrer und Fahrzeuge für eine
                        optimierte betriebliche Effizienz.
                    </p>
                </Card>
            </div>
        </div>
    </div>
</template>
<script>
import Card from '@/components/Card.vue';

export default {
    components: {
        Card,
    },
    computed: {
        showPromotion() {
            return new Date() < new Date('2025-04-30T23:59:59');
        },
    },
};
</script>
<style lang="scss">
.Pricing {
    @extend %sectionWrapper;
    padding: 78px 75px 85px;
    hyphens: auto;
    position: relative;
    z-index: 1;

    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 50px 20px;
    }

    h2,
    h3,
    h4,
    h5 {
        font-weight: 700;
    }

    .SectionHeader {
        margin: 30px 0 50px;
        text-align: center;

        @media (max-width: breakpoint(tabletPortrait)) {
            text-align: left;
        }
    }

    .Subtitle {
        font-size: 1.25rem;
        color: var(--color-blue-dark);
        margin-bottom: 8px;
    }

    .Headline {
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 2.25rem;
        color: #333;

        @media (max-width: breakpoint(tabletPortrait)) {
            text-align: left;
            font-size: 1.8rem;
        }
    }

    .HeaderInfo {
        text-align: center;
        margin-bottom: 40px;
    }

    .SetupFee {
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 500;
    }

    .TaxInfo {
        font-size: 14px;
        color: #666;
    }

    .Icon {
        margin-right: 6px;
    }

    .PromotionBanner {
        display: flex;
        align-items: center;
        background: linear-gradient(to right, #ffe89e, #fbbf24);
        padding: 20px;
        border-radius: 12px;
        margin-bottom: 30px;
        box-shadow: 0 5px 15px rgba(251, 191, 36, 0.3);

        .PromotionIcon {
            background-color: #7c2d12;
            color: white;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
            flex-shrink: 0;

            i {
                font-size: 24px;
            }
        }

        .PromotionContent {
            h3 {
                color: #7c2d12;
                margin: 0 0 5px;
                font-size: 1.25rem;
            }

            p {
                color: #7c2d12;
                margin: 0;
                font-weight: 600;
            }
        }

        @media (max-width: breakpoint(tabletPortrait)) {
            flex-direction: column;
            text-align: center;

            .PromotionIcon {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
    }

    .PromoTag {
        position: absolute;
        top: -15px;
        left: 20px;
        background-color: var(--color-red);
        color: white;
        font-weight: bold;
        padding: 5px 15px;
        border-radius: 20px;
        font-size: 14px;
        z-index: 2;
        box-shadow: 0 4px 6px rgba(239, 68, 68, 0.3);
    }

    .OldPrice {
        text-decoration: line-through;
        color: #9ca3af;
        font-size: 1.75rem;
        margin-right: 8px;
    }

    .PromotionNote {
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        color: var(--color-red);
        margin: 10px 0;
        font-weight: 600;

        i {
            margin-right: 5px;
        }
    }

    .PromotionFeature {
        background-color: #f0fff4;
        color: var(--color-green-dark);
        border-radius: 8px;
        padding: 10px !important;
        margin: 10px 0;
        border: 1px dashed var(--color-green-dark);
        font-size: 1rem;

        &::before {
            color: var(--color-green-dark) !important;
        }

        span {
            color: var(--color-green-dark);
            font-weight: 600;
        }
    }

    .Price-Wrap {
        display: flex;
        column-gap: 20px;
        justify-content: center;
        margin-bottom: 70px;

        @media (max-width: breakpoint(tabletLandscape)) {
            flex-direction: column;
            align-items: center;
            row-gap: 30px;
        }
    }

    .Price-Card {
        padding: 50px 30px;
        flex: 1;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        position: relative;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        max-width: 350px;
        background-color: white;

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        }

        @media (max-width: breakpoint(tabletLandscape)) {
            padding: 45px 25px;
            min-width: 100%;
            max-width: 100%;
        }

        &.Price-Card--highlight {
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
            position: relative;
            z-index: 2;
            border: 2px solid var(--color-orange);

            .FeatureList {
                li {
                    &:first-of-type {
                        &::before {
                            font-size: 16px;
                            content: '✓';
                        }
                    }
                }
            }

            .FeatureList,
            .SubFeatureList {
                li {
                    &::before {
                        content: '+';
                        margin-right: 5px;
                        font-size: 20px;
                        line-height: 22px;
                    }
                }
            }
            .SubFeatureList {
                li:first-of-type {
                    &::before {
                        content: '+';
                        font-size: 20px;
                        line-height: 22px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .PlanTag {
        position: absolute;
        top: -12px;
        left: 50%;
        transform: translateX(-50%);
        width: 300px;
        background-color: darken(color(red), 0%);
        color: white;
        font-weight: bold;
        padding: 5px 15px;
        border-radius: 20px;
        font-size: 16px;
        z-index: 1;
    }

    .PlanLabel {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 15px;
        color: var(--color-blue-dark);
    }

    .PlanPrice {
        font-size: 2.5rem;
        margin: 5px 0 10px;
        color: #222;
    }

    .Info {
        font-size: 16px;
        font-weight: 500;
        color: #666;
        display: block;
        margin-top: 5px;
    }

    .PlanDescription {
        margin-bottom: 25px;
        font-size: 1.125rem;
        line-height: 1.5;
        color: #333;
        padding: 10px 15px;
        border-radius: 8px;
        background-color: #f0f0f0;
        border-left: 3px solid var(--color-blue-dark);
    }

    .FeatureList {
        margin: 0 0 25px 0;
        padding: 0;
        line-height: 1.6;
        font-size: 16px;

        li {
            margin-bottom: 10px;
            list-style-type: none;
            position: relative;
            padding-left: 30px;

            &::before {
                content: '✓';
                position: absolute;
                left: 0;
                color: var(--color-blue-dark);
                font-weight: bold;
            }

            span {
                font-size: 16px;
                font-weight: 400;
            }
        }

        .SubFeatureList {
            margin: 8px 0 5px 5px;
            padding: 0;

            li {
                margin-bottom: 0;
                padding-left: 30px;
                &::before {
                    content: '✓';
                    position: absolute;
                    left: 0;
                    color: var(--color-blue-dark);
                    font-weight: bold;
                }

                span {
                    font-size: 14px;
                }
            }
        }
    }

    .DiscountInfo {
        background-color: #f5f9ff;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 20px;

        li {
            display: inline-block;
            margin-bottom: 0;
            padding-left: 20px;

            &::before {
                content: '•';
                position: absolute;
                left: 50px;
                color: var(--color-blue-dark);
                font-weight: bold;
            }

            span {
                font-size: 14px;
            }
        }

        &-Einzelfahrer {
            background-color: #fff5e6;
        }
    }

    .DiscountLabel {
        font-weight: 600;
        color: var(--color-blue-dark);
        display: block;
        margin-bottom: 5px;
    }

    .DiscountText {
        font-size: 16px;
        color: #555;
        margin: 0;
    }

    .PlanCta {
        margin-top: auto;
    }

    .Button {
        width: 100%;
        text-align: center;
        padding: 14px 25px;
        border-radius: 8px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.2s ease;
        color: white;

        &--primary {
            background-color: darken(color(warning), 15%);
            color: white;
            box-shadow: 0 0 0px 0 darken(color(warning), 15%);

            &:hover {
                box-shadow: 0 0 2px 0 darken(color(warning), 15%);
            }
        }

        &--outline {
            border: 2px solid var(--color-blue-dark);

            &:hover {
                background-color: var(--color-blue-dark);
                color: white;
            }
        }
    }

    .Modules {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 20px;
    }

    .ModulesTitle {
        font-weight: bold;
        font-size: 1.75rem;
        text-align: center;
        margin-bottom: 30px;
    }

    .ModulesWrap {
        display: flex;
        gap: 20px;
        justify-content: center;

        @media (max-width: breakpoint(tabletPortrait)) {
            flex-direction: column;
        }
    }

    .ModuleCard {
        padding: 25px;
        flex: 1;
        max-width: 450px;
        border-radius: 12px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        transition: transform 0.3s ease;
        background-color: white;

        &:hover {
            transform: translateY(-5px);
        }

        h5 {
            font-weight: bold;
            font-size: 1.25rem;
            margin: 10px 0;
            color: var(--color-blue-dark);
        }

        p {
            font-size: 1rem;
            color: #555;
            line-height: 1.6;
            margin-bottom: 0;
        }
    }

    .ModuleIcon {
        font-size: 2rem;
        margin-bottom: 10px;
    }
}
</style>
