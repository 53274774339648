var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Section ProblemAwareness",
    attrs: {
      "id": "challenges"
    }
  }, [_c('div', {
    staticClass: "SectionContainer"
  }, [_c('div', {
    staticClass: "SectionHeader"
  }, [_c('div', {
    staticClass: "HeaderBadge"
  }, [_vm._v("Probleme erkennen")]), _c('h2', {
    staticClass: "SectionTitle"
  }, [_c('span', {
    staticClass: "TitleAccent"
  }, [_vm._v("Kritische Herausforderungen")]), _vm._v(" für Taxiunternehmer ")]), _c('div', {
    staticClass: "AlertBar"
  }, [_c('i', {
    staticClass: "ri-alert-line"
  }), _c('p', [_vm._v(" Diese Probleme kosten Taxiunternehmer jährlich tausende Euro und wertvolle Zeit ")])])]), _c('div', {
    staticClass: "ChallengesGrid"
  }, [_c('div', {
    staticClass: "ChallengeCard"
  }, [_c('div', {
    staticClass: "ProblemTag"
  }, [_vm._v("Problem #1")]), _c('div', {
    staticClass: "CardIcon"
  }, [_c('i', {
    staticClass: "ri-shield-line",
    staticStyle: {
      "font-size": "24px"
    }
  })]), _c('div', {
    staticClass: "CardContent"
  }, [_c('h3', [_vm._v("Gesetzliche Vorgaben")]), _c('div', {
    staticClass: "ProblemImpact"
  }, [_c('i', {
    staticClass: "ri-file-damage-line"
  }), _c('span', [_vm._v("Bußgelder bis zu 5.000€")])]), _c('p', [_vm._v(" Ständig wechselnde Anforderungen (INSIKA/TSE) führen zu hohen Bußgeldern bei Nichteinhaltung. ")])])]), _c('div', {
    staticClass: "ChallengeCard"
  }, [_c('div', {
    staticClass: "ProblemTag"
  }, [_vm._v("Problem #2")]), _c('div', {
    staticClass: "CardIcon"
  }, [_c('i', {
    staticClass: "ri-admin-line",
    staticStyle: {
      "font-size": "24px"
    }
  })]), _c('div', {
    staticClass: "CardContent"
  }, [_c('h3', [_vm._v("Aufwändige Verwaltung")]), _c('div', {
    staticClass: "ProblemImpact"
  }, [_c('i', {
    staticClass: "ri-timer-line"
  }), _c('span', [_vm._v("15+ Stunden/Woche verschwendet")])]), _c('p', [_vm._v(" Zeitverschwendung durch komplizierte Software, die mehr Probleme verursacht als löst. ")])])]), _c('div', {
    staticClass: "ChallengeCard"
  }, [_c('div', {
    staticClass: "ProblemTag"
  }, [_vm._v("Problem #3")]), _c('div', {
    staticClass: "CardIcon"
  }, [_c('i', {
    staticClass: "ri-time-line",
    staticStyle: {
      "font-size": "24px"
    }
  })]), _c('div', {
    staticClass: "CardContent"
  }, [_c('h3', [_vm._v("Hohe versteckte Kosten")]), _c('div', {
    staticClass: "ProblemImpact"
  }, [_c('i', {
    staticClass: "ri-money-euro-circle-line"
  }), _c('span', [_vm._v("Bis zu 2.500€ Mehrkosten/Jahr")])]), _c('p', [_vm._v(" Verpasste Fristen für P-Scheine und wichtige Dokumente führen zu hohen finanziellen Einbußen. ")])])])])])]);
}]

export { render, staticRenderFns }