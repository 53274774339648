var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Section HeroSection"
  }, [_c('div', {
    staticClass: "HeroOverlay"
  }), _c('div', {
    staticClass: "Content"
  }, [_c('div', {
    staticClass: "HeroContent"
  }, [_c('div', {
    staticClass: "TextContent"
  }, [_vm._m(0), _c('p', {
    staticClass: "HeroSubtitle"
  }, [_vm._v(" Die intelligente Softwarelösung für die Taxibranche von morgen. Optimieren Sie Ihre Flotte und steigern Sie Ihre Effizienz. ")]), _c('div', {
    staticClass: "HeroActions"
  }, [_c('router-link', {
    staticClass: "PrimaryAction",
    attrs: {
      "to": "/register"
    }
  }, [_c('SiteButton', [_c('span', {
    staticClass: "ButtonText"
  }, [_vm._v("Jetzt starten")]), _c('i', {
    staticClass: "ri-arrow-right-line ButtonIcon"
  })])], 1), _vm._m(1)], 1), _vm._m(2), _vm._m(3)]), _vm._m(4)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', {
    staticClass: "HeroTitle"
  }, [_c('span', {
    staticClass: "TitleHighlight"
  }, [_vm._v("Ihr zuverlässiger Partner")]), _vm._v(" für moderne Taxiverwaltung ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "SecondaryAction",
    attrs: {
      "href": "#features"
    }
  }, [_c('span', [_vm._v("Mehr erfahren")]), _c('i', {
    staticClass: "ri-arrow-down-double-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "FeatureHighlights"
  }, [_c('div', {
    staticClass: "FeatureItem"
  }, [_c('div', {
    staticClass: "FeatureIcon"
  }, [_c('i', {
    staticClass: "ri-emotion-happy-line"
  })]), _c('span', [_vm._v("Benutzerfreundlich")])]), _c('div', {
    staticClass: "FeatureItem"
  }, [_c('div', {
    staticClass: "FeatureIcon"
  }, [_c('i', {
    staticClass: "ri-money-dollar-circle-line"
  })]), _c('span', [_vm._v("Kosten reduzieren")])]), _c('div', {
    staticClass: "FeatureItem"
  }, [_c('div', {
    staticClass: "FeatureIcon"
  }, [_c('i', {
    staticClass: "ri-line-chart-line"
  })]), _c('span', [_vm._v("Performance steigern")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HeroStats"
  }, [_c('div', {
    staticClass: "StatItem"
  }, [_c('span', {
    staticClass: "StatNumber"
  }, [_vm._v("500+")]), _c('span', {
    staticClass: "StatLabel"
  }, [_vm._v("Zufriedene Kunden")])]), _c('div', {
    staticClass: "StatDivider"
  }), _c('div', {
    staticClass: "StatItem"
  }, [_c('span', {
    staticClass: "StatNumber"
  }, [_vm._v("98%")]), _c('span', {
    staticClass: "StatLabel"
  }, [_vm._v("Kundenzufriedenheit")])]), _c('div', {
    staticClass: "StatDivider"
  }), _c('div', {
    staticClass: "StatItem"
  }, [_c('span', {
    staticClass: "StatNumber"
  }, [_vm._v("24/7")]), _c('span', {
    staticClass: "StatLabel"
  }, [_vm._v("Support")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HeroVisual"
  }, [_c('div', {
    staticClass: "HeroImageContainer"
  }, [_c('img', {
    staticClass: "HeroImage",
    attrs: {
      "src": "/product/dashboard.png",
      "alt": "TaxiFusion Dashboard"
    }
  })])]);
}]

export { render, staticRenderFns }