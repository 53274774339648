<template>
    <div class="Section SolutionSection" id="solution">
        <div class="SectionContainer">
            <div class="SectionHeader">
                <div class="HeaderBadge">Unsere Lösung</div>
                <h2 class="SectionTitle">
                    <span class="TitleAccent">Entdecken Sie taxiFusion</span>
                    Optimiert für Taxiunternehmer
                </h2>
                <div class="SuccessBar">
                    <i class="ri-check-line"></i>
                    <p>
                        Mit taxiFusion sparen Sie Zeit, reduzieren Kosten und bleiben gesetzlich
                        konform
                    </p>
                </div>
            </div>

            <div class="SolutionGrid">
                <div class="SolutionCard">
                    <div class="SolutionTag">Lösung #1</div>
                    <div class="CardIcon">
                        <i class="ri-shield-check-line" style="font-size: 24px;"></i>
                    </div>
                    <div class="CardContent">
                        <h3>Gesetzeskonform</h3>
                        <div class="SolutionImpact">
                            <i class="ri-shield-check-line"></i>
                            <span>100% rechtlich abgesichert</span>
                        </div>
                        <p>
                            Stets aktuell mit TSE-Anforderungen – ohne zusätzlichen Aufwand für Sie.
                        </p>
                    </div>
                </div>
                <div class="SolutionCard">
                    <div class="SolutionTag">Lösung #2</div>
                    <div class="CardIcon">
                        <i class="ri-dashboard-line" style="font-size: 24px;"></i>
                    </div>
                    <div class="CardContent">
                        <h3>Einfache Verwaltung</h3>
                        <div class="SolutionImpact">
                            <i class="ri-timer-line"></i>
                            <span>15+ Stunden/Woche gespart</span>
                        </div>
                        <p>
                            Intuitive Software, die Ihre Verwaltungsaufgaben vereinfacht und
                            automatisiert.
                        </p>
                    </div>
                </div>

                <div class="SolutionCard">
                    <div class="SolutionTag">Lösung #3</div>
                    <div class="CardIcon">
                        <i class="ri-money-euro-circle-line" style="font-size: 24px;"></i>
                    </div>
                    <div class="CardContent">
                        <h3>Erinnerungen</h3>
                        <div class="SolutionImpact">
                            <i class="ri-money-euro-circle-line"></i>
                            <span>Bis zu 2.500€ gespart/Jahr</span>
                        </div>
                        <p>
                            Intelligente Benachrichtigungen für P-Scheine, TÜV und mehr sichern Ihre
                            Einnahmen.
                        </p>
                    </div>
                </div>
            </div>

            <!-- Transformed to a CTA card -->
            <div class="SolutionCTACard">
                <div class="CTACardContent">
                    <div class="CTACardHeader">
                        <div class="CTAIcon">
                            <i class="ri-rocket-line"></i>
                        </div>
                        <h3>Starten Sie Ihr effizienteres Taxiunternehmen</h3>
                    </div>

                    <div class="CTACardBody">
                        <p>
                            <span class="Highlight">taxiFusion</span> ist mehr als nur eine
                            Verpflichtung zur Datenspeicherung. Unser Ziel ist es, Ihnen die
                            Werkzeuge an die Hand zu geben, die Sie benötigen, um Ihr Geschäft
                            effizienter und rentabler zu gestalten.
                        </p>
                        <router-link to="/register">
                            <Button size="large">
                                Jetzt Registrieren <i class="ri-arrow-right-line"></i>
                            </Button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/widgets/Button.vue';

export default {
    name: 'SolutionSection',
    components: {
        Button,
    },
};
</script>

<style lang="scss">
.SolutionSection {
    padding: 100px 0 150px;
    position: relative;
    overflow: hidden;
    @extend %sectionWrapper;

    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 100px 0 70px;
    }

    .SectionContainer {
        @extend %sectionWrapper;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        padding: 0 20px;
    }

    .SectionHeader {
        margin-bottom: 70px;
        position: relative;

        .HeaderBadge {
            display: inline-block;
            background-color: rgba(16, 185, 129, 0.1);
            color: #188d66;
            padding: 8px 20px;
            border-radius: 100px;
            font-weight: 600;
            font-size: 0.95rem;
            margin-bottom: 20px;
        }

        .SectionTitle {
            font-size: 3rem;
            font-weight: 800;
            color: #2d3748;
            margin: 0 0 30px;
            line-height: 1.2;

            .TitleAccent {
                display: block;
                margin-bottom: 5px;
                color: #188d66;
            }

            @media (max-width: breakpoint(tabletPortrait)) {
                font-size: 2.4rem;
            }

            @media (max-width: breakpoint(mobile)) {
                font-size: 2rem;
            }
        }

        .SuccessBar {
            background-color: rgba(16, 185, 129, 0.08);
            border-left: 4px solid #188d66;
            padding: 15px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            max-width: 800px;
            margin: 0 auto;
            border-radius: 8px;
            display: none;
            i {
                color: #188d66;
                font-size: 24px;
            }

            p {
                margin: 0;
                font-size: 1.1rem;
                color: #4a5568;
                font-weight: 500;

                @media (max-width: breakpoint(mobile)) {
                    font-size: 0.95rem;
                }
            }
        }
    }

    .SolutionGrid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 40px;
        margin-bottom: 70px;

        @media (max-width: breakpoint(mobile)) {
            grid-template-columns: 1fr;
            gap: 40px;
        }
    }

    .SolutionCard {
        background: white;
        border-radius: 16px;
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        border: 1px solid #e2e8f0;

        .SolutionTag {
            position: absolute;
            top: 15px;
            right: 15px;
            background-color: rgba(16, 185, 129, 0.1);
            color: #188d66;
            padding: 5px 12px;
            border-radius: 20px;
            font-size: 0.8rem;
            font-weight: 700;
            letter-spacing: 0.5px;
        }

        &:hover {
            transform: translateY(-10px);
            box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15);
            border-color: #188d66;

            .CardIcon {
                background-color: #188d66;
                color: white;
            }
        }

        .CardIcon {
            width: 70px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #2d3748;
            color: white;
            border-radius: 20px;
            margin: -35px auto 15px;
            box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
            transition: all 0.3s ease;
            z-index: 2;
            border: 5px solid white;
        }

        .CardContent {
            padding: 5px 25px 30px;

            h3 {
                font-size: 1.5rem;
                color: #2d3748;
                font-weight: 700;
                margin: 0 0 15px;
            }

            p {
                color: #4a5568;
                font-size: 1rem;
                margin: 0 0 15px;
                line-height: 1.6;
            }

            .SolutionImpact {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                background-color: rgba(16, 185, 129, 0.08);
                padding: 8px 15px;
                border-radius: 8px;
                margin-bottom: 15px;

                i {
                    color: #188d66;
                }

                span {
                    font-weight: 600;
                    color: #188d66;
                    font-size: 0.9rem;
                }
            }
        }
    }

    /* New CTA Card Styles */
    .SolutionCTACard {
        margin-top: 80px;
        background: linear-gradient(135deg, #ffffff 0%, #f7fafc 100%);
        border-radius: 20px;
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
        border: 1px solid #e2e8f0;
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 6px;
            background: linear-gradient(90deg, #188d66 0%, #10b981 100%);
        }

        .CTACardContent {
            padding: 40px;

            @media (max-width: breakpoint(tabletPortrait)) {
                padding: 30px 25px;
            }
        }

        .CTACardHeader {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            @media (max-width: breakpoint(mobile)) {
                flex-direction: column;
            }

            .CTAIcon {
                flex: 0 0 60px;
                height: 60px;
                background: linear-gradient(135deg, #188d66 0%, #10b981 100%);
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 20px;
                box-shadow: 0 10px 20px rgba(16, 185, 129, 0.25);

                @media (max-width: breakpoint(mobile)) {
                    margin: 0 auto 20px;
                }

                i {
                    font-size: 28px;
                    color: white;
                }
            }

            h3 {
                font-size: 1.8rem;
                font-weight: 700;
                color: #2d3748;
                margin: 0;
                line-height: 1.3;

                @media (max-width: breakpoint(mobile)) {
                    font-size: 1.5rem;
                }
            }
        }

        .CTACardBody {
            color: #4a5568;
            font-size: 1.1rem;
            line-height: 1.7;

            p {
                margin-bottom: 30px;
            }

            .Highlight {
                font-weight: 800;
                color: #188d66;
            }

            .CTABenefits {
                display: flex;
                flex-wrap: wrap;
                gap: 15px;
                margin-bottom: 30px;

                .BenefitItem {
                    display: flex;
                    align-items: center;
                    background-color: rgba(16, 185, 129, 0.08);
                    padding: 10px 16px;
                    border-radius: 50px;

                    i {
                        color: #188d66;
                        font-size: 18px;
                        margin-right: 8px;
                    }

                    span {
                        font-weight: 600;
                        color: #2d3748;
                        font-size: 0.95rem;
                        white-space: nowrap;
                    }
                }
            }

            .FinalPoint {
                background: linear-gradient(
                    90deg,
                    rgba(16, 185, 129, 0.08) 0%,
                    rgba(16, 185, 129, 0.01) 100%
                );
                border-left: 4px solid #188d66;
                padding: 15px 20px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                margin-bottom: 30px;

                i {
                    font-size: 24px;
                    color: #188d66;
                    margin-right: 15px;
                    flex-shrink: 0;
                }

                p {
                    margin: 0;
                    color: #2d3748;
                    font-weight: 600;
                    font-size: 1rem;
                }
            }
        }

        .CTACardAction {
            margin-top: 10px;

            .CTAButton {
                display: inline-flex;
                align-items: center;
                gap: 10px;
                background: linear-gradient(135deg, #188d66 0%, #10b981 100%);
                color: white;
                font-weight: 600;
                font-size: 1.1rem;
                padding: 16px 36px;
                border-radius: 12px;
                text-decoration: none;
                transition: all 0.3s ease;
                box-shadow: 0 10px 25px rgba(16, 185, 129, 0.25);
                margin-bottom: 15px;

                i {
                    transition: transform 0.3s ease;
                }

                &:hover {
                    transform: translateY(-3px);
                    box-shadow: 0 15px 30px rgba(16, 185, 129, 0.35);

                    i {
                        transform: translateX(5px);
                    }
                }
            }

            .CTASecondary {
                font-size: 0.9rem;
                color: #718096;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                .Dot {
                    margin: 0 8px;
                    color: #cbd5e0;
                }
            }
        }
    }

    .SolutionCTA {
        display: none; /* Hide the old CTA section */
    }
}
</style>
