var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Section Partners"
  }, [_vm._m(0), _c('div', {
    staticClass: "PartnerCategories"
  }, [_c('div', {
    staticClass: "PartnerCategory"
  }, [_vm._m(1), _c('div', {
    staticClass: "TaxameterGrid"
  }, _vm._l(_vm.taxameterPartners, function (partner, index) {
    return _c('Card', {
      key: index,
      staticClass: "TaxameterCard"
    }, [_c('div', {
      staticClass: "PartnerImageContainer"
    }, [_c('img', {
      attrs: {
        "src": "/partners/".concat(partner.logo),
        "alt": partner.name
      }
    })]), _c('div', {
      staticClass: "TaxameterInfo"
    }, [_c('h4', [_vm._v(_vm._s(partner.name))]), _c('span', {
      staticClass: "TaxameterDescription"
    }, [_vm._v(_vm._s(partner.description))])])]);
  }), 1)]), _c('div', {
    staticClass: "PartnerCategory"
  }, [_vm._m(2), false ? _c('div', {
    staticClass: "PartnerGrid"
  }, _vm._l(_vm.umruesterPartners, function (partner, index) {
    return _c('Card', {
      key: index,
      staticClass: "PartnerCard"
    }, [_c('div', {
      staticClass: "PartnerImageContainer"
    }, [_c('img', {
      attrs: {
        "src": "/partners/".concat(partner.logo),
        "alt": partner.name
      }
    })]), _c('div', {
      staticClass: "PartnerContent"
    }, [_c('h4', [_vm._v(_vm._s(partner.name))]), _c('p', [_vm._v(_vm._s(partner.location))]), _c('a', {
      staticClass: "AppointmentButton",
      attrs: {
        "href": partner.link,
        "target": "_blank"
      }
    }, [_c('span', [_vm._v("Termin vereinbaren")]), _c('svg', {
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "16",
        "height": "16",
        "viewBox": "0 0 24 24",
        "fill": "none",
        "stroke": "currentColor",
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }, [_c('path', {
      attrs: {
        "d": "M5 12h14"
      }
    }), _c('path', {
      attrs: {
        "d": "M12 5l7 7-7 7"
      }
    })])])])]);
  }), 1) : _vm._e(), _vm._m(3)]), _c('div', {
    staticClass: "PartnerCategory"
  }, [_vm._m(4), _c('div', {
    staticClass: "SpecialOfferContainer"
  }, [_c('div', {
    staticClass: "SpecialOffer"
  }, [_vm.showPromotion ? _c('div', {
    staticClass: "OfferBadge"
  }, [_c('i', {
    staticClass: "ri-time-line",
    staticStyle: {
      "font-size": "20px",
      "color": "white"
    }
  }), _c('span', [_vm._v("LIMITIERTE AKTION")]), _vm._v(" Nur bis 30. April 2025! ")]) : _vm._e(), _c('div', {
    staticClass: "OfferContent"
  }, [_c('div', {
    staticClass: "OfferInfo"
  }, [_c('div', {
    staticClass: "PartnerLogo"
  }, [_c('img', {
    attrs: {
      "src": "/partners/".concat(_vm.paymentPartners.logo),
      "alt": _vm.paymentPartners.name
    }
  })]), _c('h4', [_vm._v(_vm._s(_vm.paymentPartners.name))]), _c('div', {
    staticClass: "OfferDescription"
  }, [_c('div', {
    staticClass: "SpecialPromotion"
  }, [_c('div', {
    staticClass: "PromoHeader"
  }, [_vm.showPromotion ? _c('h4', [_vm._v(" myPOS Go 2 Terminal KOSTENLOS ")]) : _c('h4', [_vm._v("myPOS Go 2 Terminal für 19€")])]), _vm.showPromotion ? _c('p', {
    staticClass: "PromoDescription"
  }, [_vm._v(" Bei Registrierung erhalten Sie myPOS Go 2 Terminal komplett kostenlos! Dieses Angebot ist nur bis zum 30. April 2025 gültig. ")]) : _c('p', [_vm._v(_vm._s(_vm.paymentPartners.description))]), _vm.showPromotion ? _c('div', {
    staticClass: "PromoBenefits"
  }, [_vm._m(5), _vm._m(6)]) : _vm._e()])]), _c('a', {
    staticClass: "SpecialOfferButton",
    attrs: {
      "href": _vm.paymentPartners.link
    }
  }, [_vm.showPromotion ? _c('span', [_vm._v("Gratis Terminal sichern")]) : _c('span', [_vm._v("Jetzt kaufen")]), _c('i', {
    staticClass: "ri-arrow-right-line",
    staticStyle: {
      "font-size": "20px"
    }
  })])]), _vm._m(7)])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SectionHeader"
  }, [_c('span', {
    staticClass: "PreTitle"
  }, [_vm._v("Starke Partner an Ihrer Seite")]), _c('h2', {
    staticClass: "MainTitle"
  }, [_vm._v("Unsere Partner")]), _c('p', {
    staticClass: "SectionDescription"
  }, [_vm._v(" Wir kooperieren mit erstklassigen Anbietern, um Ihnen die beste Taxi-Management-Lösung zu bieten ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "CategoryHeader"
  }, [_c('h3', [_vm._v("Taxameter Partner")]), _c('span', {
    staticClass: "CategoryDescription"
  }, [_vm._v("Zertifizierte Taxameter-Hersteller")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "CategoryHeader"
  }, [_c('h3', [_vm._v("Umrüster Partner")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "PartnerDescription"
  }, [_vm._v(" Sie möchten Ihre Daten bei TaxiFusion sichern? "), _c('br'), _vm._v(" Sagen Sie einfach Ihrer Einbau-Werkstatt Bescheid! "), _c('br'), _c('br'), _vm._v(" Ist Ihre Werkstatt noch nicht als Partner registriert? Kein Problem, die Freischaltung dauert nur wenige Werktage. Rufen Sie uns an unter "), _c('a', {
    attrs: {
      "href": "tel:+493076006505"
    }
  }, [_vm._v("+49 30 76 00 65 05")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "CategoryHeader"
  }, [_c('h3', [_vm._v("Zahlungslösungen für Ihr Taxi")]), _c('span', {
    staticClass: "CategoryDescription"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "PromoBenefit"
  }, [_c('i', {
    staticClass: "ri-check-line"
  }), _c('span', [_vm._v("Wert: 39€ GRATIS")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "PromoBenefit"
  }, [_c('i', {
    staticClass: "ri-check-line"
  }), _c('span', [_vm._v("Keine versteckten Kosten")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ProductImage"
  }, [_c('img', {
    attrs: {
      "src": "/partners/myPOS-GO2.webp",
      "alt": "alle taxiFusion Tarife"
    }
  })]);
}]

export { render, staticRenderFns }