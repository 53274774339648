var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "AppNavigation",
    attrs: {
      "id": "AppNavigation"
    }
  }, [_c('nav', {
    staticClass: "Wrapper"
  }, [_c('router-link', {
    staticClass: "Logo",
    attrs: {
      "to": "/"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/logo.svg"),
      "alt": ""
    }
  })]), _vm.isPlanBasicOrHigher ? _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/dashboard"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-dashboard-line"
  }), _vm._v(" Dashboard ")]) : _vm._e(), _vm.hasPerformanceAnalyticsModule ? _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/analytics"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-bar-chart-line"
  }), _vm._v(" Analyse ")]) : _vm._e(), _vm.hasSchedulerModule ? _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/schedular"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-calendar-line"
  }), _vm._v(" Schichtplanner ")]) : _vm._e(), _vm.hasDriverLogsModule ? _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/driversLog"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-booklet-line"
  }), _vm._v(" Fahrtenbuch ")]) : _vm._e(), _vm.isPlanBasicOrHigher ? _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/revenue"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-money-dollar-circle-line"
  }), _vm._v(" Umsatz ")]) : _vm._e(), _vm.isPlanBasicOrHigher ? _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/working-time"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-time-line"
  }), _vm._v(" Arbeitszeiten ")]) : _vm._e(), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/shifts"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-repeat-line"
  }), _vm._v(" Schichten ")]), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/trips"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-steering-2-line"
  }), _vm._v(" Einzelfahrten ")]), _vm.isPlanBasicOrHigher ? _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/operationride"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-car-line"
  }), _vm._v(" Betriebsnachweise ")]) : _vm._e(), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/employees"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-team-line"
  }), _vm._v(" Mitarbeiter ")]), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/cars"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-car-fill"
  }), _vm._v(" Fahrzeuge ")]), _vm.hasSavingsCalculatorModule ? _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/savings-calculator"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-calculator-line"
  }), _vm._v(" Rücklagenrechner ")]) : _vm._e(), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/invoice"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-file-text-line"
  }), _vm._v(" Rechnungen ")]), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/settings"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ri-settings-3-line"
  }), _vm._v(" Einstellungen ")]), _vm.isPlanLite ? _c('div', {
    staticClass: "plan-upgrade-banner"
  }, [_c('router-link', {
    staticClass: "upgrade-link",
    attrs: {
      "to": "/upgrade"
    }
  }, [_c('i', {
    staticClass: "ri-arrow-up-circle-line"
  }), _vm._v(" Upgrade auf Basic ")])], 1) : _vm._e(), _c('div', {
    staticClass: "BusinessName"
  }, [_c('span', [_vm._v("Unternehmen")]), _c('br'), _c('p', [_vm._v(_vm._s(_vm.businessName))])]), _c('AppFooter'), _c('Button', {
    attrs: {
      "isLoading": _vm.isLoggingOut,
      "size": "small",
      "to": "/"
    },
    on: {
      "onClick": _vm.handleLogoutClick
    }
  }, [_c('i', {
    staticClass: "ri-logout-box-line",
    staticStyle: {
      "margin-right": "10px"
    }
  }), _c('span', [_vm._v("Logout")])]), _c('span', {
    ref: "indicator",
    staticClass: "Indicator"
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }