<template>
    <div id="app">
        <transition name="fade" as="div">
            <router-view />
        </transition>
        <portal-target multiple name="default" />
        <portal-target multiple name="dropdown" id="dropdown" />
        <portal-target multiple name="datePicker" id="datePicker" />
    </div>
</template>
<script>
import axios from 'axios';
import 'remixicon/fonts/remixicon.css';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'app',
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    methods: {
        handleAnimation() {
            const sections = document.querySelectorAll('.Section');

            sections.forEach(section => {
                const titleH2 = section.querySelectorAll('h2');
                const titleH3 = section.querySelectorAll('h3');
                const titleH4 = section.querySelectorAll('h4');
                const paragraph = section.querySelectorAll('p');

                gsap.from([...titleH2, ...titleH3, ...titleH4], {
                    scrollTrigger: {
                        trigger: section,
                        start: 'top 80%',
                        toggleActions: 'play none none reverse',
                    },
                    opacity: 0,
                    y: -50,
                    duration: 1,
                });

                gsap.from(paragraph, {
                    scrollTrigger: {
                        trigger: section,
                        start: 'top 80%',
                        toggleActions: 'play none none reverse',
                    },
                    opacity: 0,
                    y: 30,
                    duration: 1,
                    delay: 0.2,
                });
            });
        },
    },
    mounted() {
        setTimeout(() => {
            // this.handleAnimation();
        }, 100);
    },
};
</script>
<style lang="scss">
@import './styles/styles.scss';
/* open-sans-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/open-sans-v40-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/open-sans-v40-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/open-sans-v40-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

#app {
    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease-in-out;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }
}

/* total width */
body::-webkit-scrollbar {
    background-color: var(--color-white);
    width: 10px;
    height: 10px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
    background-color: var(--color-white);
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
    background-color: var(--color-blue-dark);
    border-radius: 16px;
    width: 10px;
    border: 4px solid var(--color-white);
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
    display: none;
}
</style>
