var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "App"
  }, [_vm.showPromotion && !_vm.isPromotionClosed ? _c('div', {
    staticClass: "PromotionBar",
    class: {
      'PromotionBar--bottom': _vm.isMobile
    }
  }, [_c('div', {
    staticClass: "PromotionContent"
  }, [_c('i', {
    staticClass: "ri-coupon-2-line"
  }), _vm._m(0), _c('a', {
    staticClass: "PromotionButton",
    attrs: {
      "href": "#pricing"
    }
  }, [_vm._v("Angebot sichern")]), _vm.isMobile ? _c('button', {
    staticClass: "PromotionCloseButton",
    attrs: {
      "aria-label": "Promotion schließen"
    },
    on: {
      "click": _vm.closePromotion
    }
  }, [_c('i', {
    staticClass: "ri-close-line"
  })]) : _vm._e()])]) : _vm._e(), _c('SiteHeader'), _c('HeroSection'), _c('ProblemSolution'), _c('Features'), _c('Pricing'), _c('Partner'), _c('Contact'), _c('SiteFooter')], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('b', [_vm._v("SONDERAKTION:")]), _vm._v(" 50% Rabatt auf alle taxiFusion Tarife + myPOS GO 2 Terminal GRATIS bis 30. April 2025 ")]);
}]

export { render, staticRenderFns }